.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 85px;
    padding: 55px 0 125px 0;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 68px;
}

@media (max-width: 768px) {
    .container {
        padding: 55px 32px 125px;
    }

    .container img {
        width: 100%;
    }
}