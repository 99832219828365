.modal {
    width: 1200px;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    align-self: center;
    z-index: 11;
    position: absolute;
    left: 0;
    right: 0;
    transition: all .4s ease;

    opacity: 0;
    animation: trans-in .4s backwards;
}

.modalOnFuckingSinglePage {
    width: calc(((100vw - 270px - 90px - 40px)));
    left: calc(270px + 90px);
}

@-webkit-keyframes trans-in {
    0% {transform: translateY(100px);}
    100% {transform: translateY(0px);}
}
@keyframes trans-in {
    0% {transform: translateY(100px);}
    100% {transform: translateY(0px);}
}

.modalOpen {
    opacity: 1;
    animation: trans-in .4s forwards;
}

.cross {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
}


.inner {
    display: grid;
    grid-template-columns: 500px auto;
}

.image {
    width: 100%;
    height: 100%;
}

.image img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.body {
    padding: 50px 72px;

    display: flex;
    flex-direction: column;
    gap: 24px;

}

.title {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
}

.text {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.button > div {
    place-self: flex-start;
}

@media (max-width: 768px) {
    .inner {
        display: flex;
        flex-direction: column;
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 32px;
        line-height: 38px;
        text-transform: uppercase;
        color: #C2272F;
    }

    .text {
        font-weight: 400;
        font-size: 24px;
        line-height: 150%;
        color: #414141;
    }

    .image {
        height: 417px;
    }

    .body {
        padding: 55px 32px 48px;
    }

    .button {
        margin-top: 48px;
    }
}