.modal {
    width: 900px;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
    margin: 0 auto;

    align-self: center;
    z-index: 11;
    position: absolute;
    left: 0;
    right: 0;
    top: 150px;

    transition: all .4s ease;

    opacity: 0;
    animation: trans-in .4s backwards;
}

@-webkit-keyframes trans-in {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes trans-in {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateY(0px);
    }
}

.modalOpen {
    opacity: 1;
    animation: trans-in .4s forwards;
}

.cross {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
}

.inner {
    padding: 48px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 60px;
}

.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.date {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.title {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-align: right;
    letter-spacing: 0.085em;
    text-transform: uppercase;
    color: #414141;
}

.subTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    color: #6A6B6B;
}

.head {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
}

.calendar {
    margin-top: 60px;
}

.restaurantSelectorItems {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 35px;
}

.restaurantSelectorItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 18px;
    cursor: pointer;
}


.restaurantSelectorItem:hover .restaurantSelectorItemText,
.restaurantSelectorItem.active .restaurantSelectorItemText {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #414141;
}

.restaurantSelectorItem:hover .restaurantSelectorIcon,
.restaurantSelectorItem.active .restaurantSelectorIcon {
    filter: grayscale(0);
    opacity: 1;
}

.restaurantSelectorItem.inactive .restaurantSelectorIcon {
    filter: grayscale(1);
    opacity: 0.5;
}

.restaurantSelectorItemText {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #A4A6A6;
}

.row {
    width: 100%;
    margin-top: 24px;
    display: flex;
    gap: 24px;
}

.col {
    width: 100%;
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
}


.textarea,
.input {
    border: none;
    background: rgba(164, 166, 166, 0.1);
    padding: 14px 24px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
    width: 100%;
    font-family: 'TT Norms Pro', serif;
}

.textarea::placeholder,
.input::placeholder {
    font-family: 'TT Norms Pro', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A4A6A6;
}

.textarea:focus,
.input:focus {
    outline: none;
}

.textarea {
    resize: none;
    height: 85px;
}

.inputRed {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    align-items: flex-start;
}

.inputRed label {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    color: #414141;
}

.inputRed input {
    padding: 8px 0px;
    gap: 8px;
    border: 0.5px solid #C2272F;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
    width: 100%;
}

.inputRed input::placeholder {
    color: #414141;
}

.button {
    margin-top: 24px;
}

.success {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 48px;
}

.successTitle {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
}

.successTitleIcon {
    width: 72px;
}

.successTitleText {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
    text-align: right;
    letter-spacing: 0.085em;
    text-transform: uppercase;
    color: #414141;
}

.successText {
    margin-top: 32px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #414141;
    width: 70%;

}

.successWarn {
    margin-top: 18px;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    text-transform: uppercase;
    color: #C2272F;
}

.successGrid {
    margin-top: 48px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.successGridItem {
    display: flex;
    flex-direction: column;
    gap: 6px;
    align-items: flex-start;
}

.successGridTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #414141;
}


.successGridValue {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #414141;
}

.restaurantForm {
    width: 100%;
}

.calendarDate {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    text-transform: uppercase;
    color: #A4A6A6;
}

@media (max-width: 768px) {
    .inner {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    .subTitle {
        font-size: 18px;
    }

    .modal {
        top: 0;
        width: calc(100% - 64px);
        box-sizing: border-box;
        position: relative;
    }

    .modal::after {
        content: ".";
        position: absolute;
        bottom: 120px;
        width: 1px;
        height: 1px;
    }

    .calendar {
        margin-top: 24px;
    }

    .calendar > div {
        width: 100%;
    }

    .calendarDate {
        display: block;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-align: right;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .inputRed label {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        /* identical to box height */
        text-align: right;

        /* grey */
        color: #414141;
    }

    .inputRed input {
        font-size: 24px;
        padding: 15px 0;
    }

    .input {
        font-size: 20px;
    }

    .textarea::placeholder,
    .input::placeholder {
        font-size: 18px;
    }

    .restaurantSelectorItems {
        grid-template-columns: auto auto;
    }

    .restaurantSelectorItemText,
    .restaurantSelectorItem:hover .restaurantSelectorItemText,
    .restaurantSelectorItem.active .restaurantSelectorItemText {
        font-size: 16px;
    }

    .successTitleText {
        text-align: center;
    }

    .successText {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #414141;
        width: 100%;
        margin-top: 42px;
    }

    .success {
        padding: 32px;
    }

    .cross img {
        width: 50px;
    }

    .successWarn {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-align: center;
        margin-top: 24px;
        width: 100%;
    }

    .successGrid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
    }

    .inner {
        box-sizing: border-box;
        width: 100%;
    }

    .successGridTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #414141;
    }

    .successGridValue {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #414141;
    }

    .successGridItem {
        gap: 12px;
    }

    .successGrid {
        gap: 32px 24px;
    }

    .textarea {
        height: 105px;
    }
}