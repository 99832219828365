.modal {
    flex-direction: column;
    align-items: center;
    align-self: flex-end;
    padding: 24px 32px 48px;
    position: absolute;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
    width: calc(((100vw - 270px - 90px - 40px)));
    left: calc(270px + 90px) ;

    transition: all .4s ease;

    opacity: 0;
    animation: trans-in .4s backwards;
}

@-webkit-keyframes trans-in {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes trans-in {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateY(0px);
    }
}

.modalOpen {
    opacity: 1;
    animation: trans-in .4s forwards;
}

.modal:after {
    position: absolute;
    content: 'clearfix';
    bottom: -50px;
    opacity: 0;
}

.cross {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
}


.head {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.back {
    display: flex;
    cursor: pointer;
}

.close {
    cursor: pointer;
}

.backText {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;

}

.product {
    margin-top: 14px;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 48px;
    display: grid;
    grid-template-columns: 1fr 415px;
    grid-template-rows: auto 1fr;

}

.image {
    height: 100%;
    cursor: pointer;
}

.image img {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.about {
    padding: 35px 0;
}

.titleText {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    color: #414141;
}

.title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
}

.weight {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    opacity: 0.5;
    margin-top: 12px;
}

.description {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    margin-top: 32px;
    color: #414141;
}

.ingredients span, .nutrition span {
    font-weight: 500;
}

.ingredients div {
    text-transform: lowercase;
}

.ingredients {
    display: flex;
    font-weight: 400;
    gap: 4px;
    font-size: 12px;
    line-height: 14px;
    color: #6A6B6B;
    margin-top: 32px;
}

.nutrition {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6A6B6B;
    margin-top: 24px;
}

.modifiers {
    margin-top: 24px;
}

.modifiersTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #C2272F;
}

.modifiersOptions {
    margin-top: 26px;
    display: flex;
    gap: 14px;
    flex-wrap: wrap;
}

.modifier {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    gap: 8px;
    min-width: 144px;
    min-height: 32px;
    border: 1px solid #A4A6A6;

    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    cursor: pointer;
}

.modifiersName {
    color: #6A6B6B;
}

.modifiersPrice {
    color: #A4A6A6;
}

.modifierActive {
    background: #414141;
    border: 1px solid #414141;
}

.modifierActive .modifiersName,
.modifierActive .modifiersPrice {
    color: #F9F9F9;
}

.buyBlock {
    margin-top: 32px;
    display: flex;
    gap: 18px;
    align-items: center;
}

.price {
    display: flex;
    align-items: center;
}

.priceDiscount {
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    text-decoration-line: line-through;
    text-transform: uppercase;
    color: #A4A6A6;
    margin-right: 18px;
    white-space: nowrap;
}

.priceActual {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    color: #414141;
    white-space: nowrap;
}

.additional {
    margin-top: 48px;
    width: 100%;
}

.additionalTitle {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #414141;
}

.additionalGrid {
    margin-top: 32px;
    width: 100%;
}


@media (max-width: 768px) {
    .head {
        display: none;
    }

    .image {
        height: 500px;
    }

    .product {
        display: flex;
        flex-direction: column;
        margin-top: unset;
    }

    .about {
        padding: 0 32px;
    }

    .titleText {
        font-style: normal;
        font-weight: 700;
        font-size: 36px;
        line-height: 43px;
        text-transform: uppercase;
        color: #414141;
    }

    .weight {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #A4A6A6;
    }

    .ingredients,
    .nutrition,
    .description {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #6A6B6B;
    }

    .ingredients span,
    .nutrition span {
        font-weight: 700;
        color: #A4A6A6;
    }

    .buyBlock {
        display: grid;
        grid-template-columns: auto 1fr;
        margin-top: initial;
    }

    .priceDiscount {
        font-style: normal;
        font-weight: 400;
        font-size: 32px;
        line-height: 38px;
        text-decoration-line: line-through;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .priceActual {
        font-style: normal;
        font-weight: 500;
        font-size: 48px;
        line-height: 58px;
        text-transform: uppercase;
        color: #414141;

    }

    .price {
        width: 90%;
    }

    .addButton {
        width: 100%;
    }

    .additional {
        margin-top: 65px;
        padding: 0 32px;
    }

    .additionalTitle {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.13em;
        text-transform: uppercase;
        color: #6A6B6B;
    }

    .additionalGrid {
        margin-left: -12px;
    }

    .modifier {
        font-size: 20px;
        padding: 25px 20px;
        width: 100%;
        box-sizing: border-box;
    }

    .modifiersOptions {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    .titleIcons img {
        width: 50px;
    }
}

@media (min-width: 1800px) {
    .product {
        grid-template-columns: 1fr 0.7fr;
    }
}
