.container {
    padding: 75px 0;
    display: grid;
    grid-template-columns: 1fr 660px;
}

.imagesSection {
    width: 100%;
    position: relative;
}

.overlayTop {
    top: -50px;
    left: -53px;
    position: absolute;
    background-image: url("../../../static/images/banner/tp.png");
    width: 872px;
    height: 87px;
    background-repeat: no-repeat;
}

.overlayBot1 {
    width: 544px;
    bottom: 79px;
    position: absolute;
    background-image: url("../../../static/images/banner/bt.png");
    height: 58px;
    background-repeat: no-repeat;
    left: 350px;
}

.overlayBot2 {
    width: 571px;
    bottom: 43px;
    position: absolute;
    background-image: url("../../../static/images/banner/bt2.png");
    height: 188px;
    background-repeat: no-repeat;
    left: -65px;
}

.images {
    display: flex;
    width: 100%;
    height: 593px;
}


.image {
    height: 100%;
    background-size: cover;
    background-position: center;
    width: 50%;
    transition: all .3s;
    cursor: pointer;
}

.image + .image {
    margin-left: 15px;
}

.image.active {
    width: 100%;
}

.textSection {
    width: 100%;
    padding: 50px 140px 80px 70px;
    box-sizing: border-box;
    z-index: 1;
}


.preHeading {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
}

.heading {
    font-weight: 500;
    font-size: 30px;
    line-height: 42px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #333333;
    margin-top: 15px;
    margin-bottom: 0;
}

.text {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
    margin-top: 30px;
}

.list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
    grid-gap: 48px;
    margin-top: 75px;
}

.listItemBullet {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
}

.listItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    grid-gap: 24px;
    cursor: pointer;
}

.listItemSpace .listItemBullet {
    padding-left: 20px;
}

.listItemText {
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #414141;
    text-decoration: none;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
}

.listItemTextIconGift {
    color: #c2272f;
    font-weight: 700;
    padding-left: 64px;
    background-image: url("../../../static/icons/menu-icon-gift.svg");
    background-repeat: no-repeat;
    background-position: 11px 0;
    background-size: 25px;
}

@media (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
        padding: 0
    }
    .textSection {
        padding: 50px 32px 80px 36px;

    }
    .list {
        align-items: flex-end;
    }
    .listItem {
        flex-direction: row-reverse;
    }

    .image {
        display: none;
    }

    .image.active {
        display: block;
    }

    .image + .image {
        margin-left: unset;
    }

    .image {
        height: 512px;
    }

    .overlayBot1, .overlayBot2 {
        display: none;
    }

    .heading {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 134.3%;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #333333;
    }

    .listItemText {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #414141;
    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 133.8%;
        color: #414141;
    }

    .overlayTop {
        width: 100%;
        left: initial;
        top: -25px;
    }

    .listItemTextIconGift {
        padding-left: 0;
        padding-right: 66px;
        background-position: 99% 0;
        background-size: 30px;
    }
}
