.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 32px;
    width: 100%;
    cursor: pointer;
    box-sizing: border-box;
    white-space: nowrap;
}

.button span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    text-align: right;
    letter-spacing: 0.05em;
    text-transform: uppercase;
}

.buttonWhite {
    border: 1px solid #A4A6A6;
    background: #F9F9F9;
}

.buttonWhite span {
    color: #6A6B6B;
}

.buttonRed {
    border: none;
    background: #C2272F;
}

.buttonRed span {
    color: #F9F9F9;
}

@media (max-width: 768px) {
    .button {
        padding: 22px 32px;
    }
}