.container {
    width: 100%;
    background: #414141;
}

.inner {
    padding: 64px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}

.colOne {
}

.colTwo {
}

.colOne .menu {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-gap: 21px;
}

.colTwo .menu {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 42px;
}

.menuItem a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #F9F9F9;
    text-decoration: none;
}

.socialItemIcon {
}

.socialItemText {
    font-size: 14px;
    line-height: 17px;
    color: #F9F9F9;
}

.socialItem {
    display: flex;
    grid-gap: 16px;
    align-items: center;
}

.social {
    margin-top: 24px;
}

.disclamer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    grid-gap: 16px;
    margin-top: 42px;
    color: gray;
}

.disclamerItem, .disclamerItem a {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #F9F9F9;
    opacity: 0.5;
}

.logoPayIcon {
    margin-top: 22px;
}

@media (max-width: 768px) {
    .container {
        display: flex;
        justify-content: space-between;
    }

    .inner {
        max-width: initial;
        padding: 105px 32px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: initial;
    }

    .columns {
        display: flex;
        justify-content: space-between;
        gap: 50px
    }

    .colOne .menu {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    .colTwo .menu {
        display: flex;
        flex-direction: column;
    }

    .disclamer {
        margin-top: 40px;
        align-items: flex-start;
    }

    .footerLogo {
        padding-right: 18px;
        align-self: flex-start;
    }

    .social {
        margin-top: 40px;
    }
    .socialItemText {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #F9F9F9;
    }

    .disclamerItem, .disclamerItem a {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: flex-end;
        color: #F9F9F9;
    }

    .logoPayIcon {
        margin-top: 0;
        margin-bottom: 24px;
    }
}
