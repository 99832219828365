.container {
    margin: 52px 0 120px 0;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.titleBack {
    background: #f9f9f9;
    z-index: 2;
    padding: 0 72px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
}

.titleBack span {
    height: 20px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    padding: 0 72px 0 82px;
    text-transform: uppercase;
    color: #414141;
    background: #f9f9f9;
    position: relative;
    z-index: 2;
}

.title:after {
    content: '';
    position: absolute;
    width:  100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 50%;
    z-index: 1;

    /*left: calc(-72px - ((100vw - 1440px)/ 2));*/
}

.inner {
    padding: 0 72px;
    margin-top: 56px;
    display: grid;
    grid-template-columns: auto 445px;
    gap: 70px;
}


.map {
    width: 100%;
    height: 525px;
}

.map iframe {
    height: 525px;
    width: 100%;
}

.workHours {
    display: grid;
    grid-template-columns: 20px auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
    "icon title"
    "icon sub";
    column-gap: 16px;
    row-gap: 8px;
}

.workHoursIcon {
    grid-area: icon;
}

.workHoursTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #414141;
    grid-area: title;
}

.workHoursSubtitle {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #414141;
    grid-area: sub;
}

.zones {
    margin-top: 32px;
    display: flex;
    align-items: flex-start;
    gap: 22px
}

.zoneDots {
    min-width: 40px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    position: relative;
    justify-content: center;
    align-items: center;
}

.zoneDots:before {
    content: '';
    background: #A4A6A6;
    width: 1px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 50%;
}

.zoneDot {
    width: 24px;
    height: 24px;
    border-radius: 100px;
    background: #0d6efd;
    z-index: 2;
    cursor: pointer;
    transition: all .3s;
}

.zoneDot.active {
    width: 38px;
    height: 38px;
}

.zoneDotGreen {
    background: #A4CFAE
}

.zoneDotYellow {
    background: #D7E4A5
}

.zoneDotOrange {
    background: #D1A56C
}

.zoneDotBrown {
    background: #73624E
}


.zoneDetails {
    display: flex;
    flex-direction: column;
    gap: 22px;
    width: 100%;

}

.zoneDescription {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px 32px 24px 24px;
    gap: 28px;
    background: #F9F9F9;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}


.zonePickup {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 24px;
    gap: 12px;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.zonePickupIcon {

}

.zoneDescriptionIcon {
    margin: 4px 0;
    width: 10px;
    height: 10px;
    border-radius: 50px;
}

.zoneDescriptionTexts {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.zoneDescriptionName {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #414141;
}

.zoneDescriptionItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.zoneDescriptionTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #414141;
}

.zoneDescriptionSubs {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6A6B6B;

    display: flex;
    flex-direction: column;
    gap: 8px;
}

@media (max-width: 768px) {
    .titleBack {
        display: none;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .titleText {
        padding: 0;
        margin: 0 32px;
    }

    .title:after {
        position: absolute;
        content: '';
        background: #A4A6A6;
        width: 100%;
        top: 50%;
        bottom: initial;
        height: 0.7px;
        right: 0;
    }

    .inner {
        display: flex;
        flex-direction: column;
        gap: 48px;
        padding: 0 32px;
    }

    .map {
        height: 425px;
    }

    .workHoursTitle {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #6A6B6B;
    }

    .workHoursSubtitle {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 133%;
        color: #6A6B6B;
    }

    .zones {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 0;
    }

    .zoneDots {
        display: flex;
        flex-direction: row;
        min-height: 60px;
    }

    .zoneDots:before {
        width: 100%;
        height: 1px;
        position: absolute;
        top: 50%;
        left: 0;
    }

    .zoneDot {
        width: 36px;
        height: 36px;
    }

    .zoneDot.active {
        width: 58px;
        height: 58px;
    }

    .zoneDescriptionName {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #414141;
    }

    .zoneDescriptionTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 133%;
        text-transform: uppercase;
        color: #414141;
    }

    .zoneDetails {
        gap: 24px;
    }

    .zoneDescription {
        padding: 48px 32px 48px 24px;
    }

    .zoneDescriptionIcon {
        width: 24px;
        height: 24px;
    }

    .zoneDescriptionSubs {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #6A6B6B;
    }

    .zonePickupIcon {
        width: 40px;
        height: 40px;
        align-self: center;
    }

    .zonePickupIcon img {
        width: 100%;
        height: 100%;
    }

    .zoneDescriptionItem {
        gap: 6px
    }
}