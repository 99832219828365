.checkbox {
    display: flex;
    grid-gap: 18px;
    align-items: center;
    cursor: pointer;
}

.checkboxBox {
    width: 24px;
    min-width: 24px;
    min-height: 24px;
    height: 24px;
    border: 0.75px solid #6A6B6B;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #414141;
}

.agreement span, .agreement a {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A4A6A6;
}

.question {
    display: flex;
    flex-direction: column;
    gap: 48px;
}
.questionStars {
    gap: 24px;
}

.questionTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414141;
}

.questionTitleBold {
    font-style: normal;

    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-transform: uppercase;

    color: #414141;
}

.questionTitleBold span {
    font-size: 20px;
    font-weight: 500;
    text-transform: lowercase;
}

.questionAnswers {
    display: flex;
    gap: 32px;
}

.questionAnswer {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #414141;

    padding: 0 12px;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 250px;
    height: 55px;
    background: rgba(164, 166, 166, 0.1);
    cursor: pointer;
}

.questionAnswer.active {
    background: #414141;
    color: #fff;
}

.question + .question {
    margin-top: 32px;
    padding-top: 32px;
    border-top: 1px solid #A4A6A6;
}

.questionStar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

.questionStar img {
    filter: grayscale(1);
    opacity: 0.5;
}

.questionStar.active img {
    filter: none;
    opacity: 1;
}

.questionImagesInputContainer {
    display: flex;
    align-items: center;
    padding-top: 15px;
}

.questionImagesInput {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    border: 0;
    padding: 0;
    white-space: nowrap;
    clip-path: inset(100%);
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.questionImagesLabelText {
    font-size: 14px;
    font-weight: 500;
    text-decoration: underline;
    color: #C2272F;
    cursor: pointer;
}

.questionImagesTextLimit {
    margin: 0;
    margin-left: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #6A6B6B;
}

.questionImagesTextError {
    font-size: 12px;
    color: #000;
    font-weight: 500;
    text-transform: uppercase;
    padding-left: 30px;
    padding-top: 10px;
    padding-bottom: 9px;
    background-image: url("../../static/icons/agent-restaurant/error.svg");
    background-color: transparent;
    background-position: left center;
    background-repeat: no-repeat;
}

.questionImagesItemsContainer {
    list-style: none;
    padding: 0;
    margin: 0;
    display: grid;
    grid-template-columns: repeat(5, 128px);
    gap: 20px;
    justify-content: center;
    padding-top: 20px;
}

.questionImagesItem {
    position: relative;
    height: 128px;
}

.questionImagesItemImg {
    display: block;
    object-fit: cover;
}

.questionImagesItemBtnDelete {
    position: absolute;
    top: 6px;
    right: 6px;
    cursor: pointer;
    display: block;
    width: 16px;
    height: 16px;
    background-image: url("../../static/icons/agent-restaurant/close.svg");
    background-color: #414141;
    background-position: center;
    background-repeat: no-repeat;
    border: 0;
}

.textarea,
.input {
    border: none;
    background: rgba(164, 166, 166, 0.1);
    padding: 14px 24px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
    width: 100%;
    font-family: 'TT Norms Pro', serif;
}

.textarea::placeholder,
.input::placeholder {
    font-family: 'TT Norms Pro', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A4A6A6;
}

.textarea:focus,
.input:focus {
    outline: none;
}

.textarea {
    resize: none;
    height: 120px;
}

.formHolder {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
}

.formRow {
    display: flex;
    grid-gap: 24px;
}

.formInput {
    width: 100%;
}

.formInput input, .formInput textarea {
    width: 100%;
    padding: 15px 24px;
    background: rgba(106, 107, 107, 0.1);
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    box-sizing: border-box;
    border: none;
}

.formInput input::placeholder, .formInput textarea::placeholder {
    font-family: 'TT Norms Pro', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
}

.formUpload {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    cursor: pointer;
}

.formUpload span {
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #6A6B6B;
    max-width: calc(100% - 35px);
    word-wrap: break-word;
}

.formUpload img {
    margin-left: 10px;
}

.formInput textarea:disabled, .formInput input:disabled {
    color: rgba(65, 65, 65, 0.51);
}

.restaurants {
    width: 100%;
    padding: 42px 32px;
    gap: 48px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.restaurant {
    display: flex;
    gap: 24px;
    cursor: pointer;
    align-items: center;
}

.restaurantText {
    display: flex;
    flex-direction: column;
}

.restaurantTitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #414141;
}

.restaurantAddress {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #414141;
}

.formUpload {
    align-items: center;
}

@media (max-width: 768px) {
    .checkbox:not(.agreement) span {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #414141;
    }

    .agreement span, .agreement a {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 133%;
        color: #A4A6A6;
    }

    .restaurantTitle {
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
    }
    .restaurantAddress {
        font-size: 14px;
        line-height: 17px;
    }

    .input, .textarea, .input::placeholder, .textarea::placeholder,
    .formInput input, .formInput textarea, .formInput input::placeholder, .formInput textarea::placeholder {
        font-size: 20px;
    }

    .input, .textarea, .formInput input, .formInput textarea {
        padding: 24px 24px;
    }

    .formUpload span {
        font-size: 18px;
    }

    .button span {
        font-size: 20px;
        font-weight: bold;
    }

    .questionStars .questionAnswers {
        gap: 10px;
    }

    .questionImagesInputContainer {
        display: block;
    }

    .questionImagesLabel {
        display: block;
        margin-bottom: 6px;
    }

    .questionImagesTextLimit {
        margin-left: 0;
    }

    .questionImagesItemsContainer {
        grid-template-columns: repeat(4, 103px);
        gap: 14px;
    }

    .questionImagesItem {
        height: 103px;
    }

    .questionImagesItem img {
        width: 103px;
        height: 103px;
    }

    .questionImagesTextError {
        margin-bottom: 0;
    }
}
