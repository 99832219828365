.container {
    padding: 72px;

    max-width: 1440px;
    margin: 100px auto 0;
}

.title {
}

.preHead {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
}

.head {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 0;
}

.inner {
    margin-top: 72px;
    display: flex;
    justify-content: space-between;
}

.form {
    width: 520px;
}

.faq {
    width: 638px;
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    height: 500px;
}

.faqQuestion {
}

.faqQuestionTitle {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
}

.faqQuestionTitleText {
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #A4A6A6;
    padding: 5px 0px;
}


.faqQuestionTitleIcon {
    margin-right: 13px;
    transform: rotate(0deg);
    transform-origin: center;
    transition: all .3s ease;
    cursor: pointer;
}

.faqQuestion.active .faqQuestionTitleIcon {
    transform: rotate(-180deg);
}

.faqQuestion.active .faqQuestionTitleIcon svg path {
    fill: #C2272F;
    stroke: #C2272F;
}

.faqQuestionInner {

}

.faqQuestionText {
    overflow: hidden;
    max-height: 0;
    transition: all .3s ease;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
    padding-top: 20px;
    width: 95%;
}

.faqQuestion.active .faqQuestionText {
    max-height: 100px;
}

.faqQuestion.active .faqQuestionTitleText {
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #414141;
    border-bottom: 1px solid #C2272F;
}

@media (max-width: 768px) {
    .container {
        padding: 0 32px;
    }

    .inner {
        flex-direction: column;
    }

    .faq {
        width: 100%;
        margin-top: 120px;
        margin-bottom: 240px;
    }

    .faqQuestionText {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 140.8%;
        color: #414141;
    }

    .faqQuestion.active .faqQuestionText {
        max-height: 200px;
    }

    .faqQuestionTitleText,
    .faqQuestion.active .faqQuestionTitleText {
        font-size: 18px;
    }
}
