.container {
    margin-top: 52px;
}

.inner {
    padding: 72px 72px 132px 72px;
    display: grid;
    grid-template-columns: 1fr 480px;
    gap: 142px;
}

.title {
    display: flex;
    justify-content: center;
    position: relative;
}

.titleText {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #414141;
    background: #f9f9f9;
    z-index: 2;
    padding: 0 72px;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 50%;
    opacity: 0.9;
}

.text {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 148.3%;
    color: #414141;
}

.call {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #414141;
}

.call a {
    color: #414141;
    text-transform: lowercase;
}

.description {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 52px 32px;
    padding: 72px 0 72px 50px;
}

.gridItem {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.gridTitle {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #A4A6A6;
}

.gridText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.order {
    display: grid;
    grid-template-columns: 260px auto;
}

.orderInfo {
    width: 100%;
}

.products {
    display: flex;
    flex-direction: column;
    gap: 28px
}

.product {
    align-items: center;
    display: grid;
    grid-template-columns: 108px 180px 30px 57px;
    gap: 35px
}

.productImage {
}

.productImage img {
    object-fit: cover;
    width: 108px;
    height: 85px;
}

.productName {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #414141;
}

.productTitle {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.productSub span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A4A6A6;
}

.productSub span + span {
    margin-left: 18px;
}

.productCount {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #414141;
    justify-self: flex-end;
}

.productPrice {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #A4A6A6;
    justify-self: flex-end;
}

.productTotal {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.productTotalRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productTotalPrice {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #A4A6A6;
}

.productTotalLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #A4A6A6;
}

.orderTotal {
    margin-top: 20px;
    padding-top: 18px;
    border-top: 1px solid #A4A6A6;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderTotalLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #414141;
}

.orderTotalPrice {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414141;
}

.buttons {
    padding: 0px 0px 0px 50px;
    display: flex;
    gap: 45px
}

.textRed {
    margin: 0;
    margin-bottom: 15px;
    color: #C2272F;
}

@media (max-width: 768px) {
    .titleBack {
        display: none;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .titleText {
        margin: 0 32px;
        padding: 0 32px;
    }

    .title:after {
        position: absolute;
        content: '';
        background: #A4A6A6;
        width: 100%;
        top: 50%;
        bottom: initial;
        height: 0.7px;
        right: 0;
    }

    .inner {
        padding: 48px 32px;
        display: flex;
        flex-direction: column;
        gap: 72px;
    }

    .grid {
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 52px;
        margin-top: 52px;
    }

    .gridTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .gridText {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

    .product {
        display: grid;
        flex-direction: row;
        grid-template-columns: 200px auto;
        grid-template-rows: auto auto auto;
        gap: 24px;
        grid-template-areas:
                "image title"
                "image options"
                "image numbers";
    }

    .productImage {
        grid-area: image
    }

    .productTitle {
        grid-area: title
    }

    .productName {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #414141;
    }

    .productSub span {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #A4A6A6;
    }

    .productSub span + span {
        margin: 0;
    }

    .productNumbers {
        grid-area: numbers;
        display: flex;
        justify-content: space-between;
    }

    .productSub {
        display: flex;
        flex-direction: column;
        grid-area: options;
        gap: 8px
    }

    .productImage img {
        width: 200px;
        height: 200px;
    }

    .productPrice {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #414141;
    }

    .productCount {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .productTotalLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .orderTotalLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

    .productTotalPrice {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .orderTotalPrice {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        text-transform: uppercase;
        color: #414141;
    }

    .call {
        margin-top: 32px;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        text-transform: uppercase;

        /* grey */
        color: #414141;

    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 32px;

        /* grey */
        color: #414141;
        width: 100%;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        padding: 0;
        gap: 24px;
    }

    .buttons > div {
        width: 100%;
        padding: 24px;
    }

    .buttons > div span {
        font-size: 24px;
    }

    .textRed {
        margin: 0;
        margin-top: 30px;
        color: #C2272F;
    }
}
