.container {
    margin: 52px 0 120px 0;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.titleBack {
    background: #f9f9f9;
    z-index: 2;
    padding: 0 72px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    cursor: pointer;
}

.titleBack span {
    height: 20px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    padding: 0 72px 0 82px;
    text-transform: uppercase;
    color: #414141;
    background: #f9f9f9;
    position: relative;
    z-index: 2;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 50%;
    z-index: 1;
}

.selector {
    display: flex;
    margin-top: 38px;
}

.selectorClearfix {
    width: 289px;
}

.restTags {
    display: flex;
    gap: 24px;
}

.restTag {
    background: #F9F9F9;
    border: 1px solid #A4A6A6;
    padding: 7.5px 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
}
.selectorItems {
    display: flex;
    gap: 44px;
}

.selectorItem {
    display: flex;
    gap: 20px;
    align-items: center;
    cursor: pointer;
}

.selectorIcon img {
    width: 100%;
}

.selectorIcon {
    width: 18px;
    height: 18px;
}

.selectorTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #414141;
    opacity: .5;
}

.selectorItem:hover .selectorTitle,
.selectorTitle.active {
    opacity: 1;
}

.inner {
    padding: 0 72px;
    display: flex;
    gap: 68px;
    max-width: 1440px;
    margin: 68px auto 0;
    align-items: center;
}

.gallery {
    display: flex;
    gap: 22px;
}

.galleryImages {
    display: flex;
    flex-direction: column;
    gap: 18px;
    align-items: center;
    justify-content: center;
}

.galleryImage {
    width: 147px;
    height: 121px;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.galleryActive img,
.galleryImage img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.galleryActive {
    width: 554px;
    height: 459px;

    box-shadow: 0px 4.32432px 15.1351px 5.40541px rgba(0, 0, 0, 0.2);
}

.details {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.restTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #000000;
}

.restAboutLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #C2272F;
}

.restAboutValue {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414141;
    text-decoration: none;
}

.restAboutSub a,
.restAboutSub {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
}

.restAbout {
    display: flex;
    gap: 48px;
}

.restButtons {
    display: flex;
    gap: 32px;
}

.restAboutSect {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 225px;
    text-decoration: none;
}

@media (max-width: 768px) {
    .titleBack {
        /*display: none;*/
        position: absolute;

        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        color: #6A6B6B;

        left: 20px;
        top: -30px;
        background: transparent;
        padding: 0;
    }

    .title {
        justify-content: center;
    }

    .titleText {
        margin: 0 32px;
        max-width: 310px;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
        background: #f9f9f9;
        padding: 0 32px;
    }

    .title:after {
        position: absolute;
        content: '';
        background: #A4A6A6;
        width: 100%;
        top: 50%;
        bottom: initial;
        height: 0.7px;
        right: 0;
    }

    .inner {
        padding: 0 32px;
        flex-direction: column;
        display: flex;
        align-items: flex-start;
        gap: 32px;
        margin-top: 40px;
    }

    .gallery {
        width: 100%;
        display: flex;
        flex-direction: column-reverse;
    }

    .galleryActive {
        width: 100%;
    }

    .galleryImages {
        display: flex;
        flex-direction: row;
    }

    .restAboutSub {
        display: none;
    }

    .restTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #000000;
    }

    .restOperating, .restOperating a {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #6A6B6B;
    }

    .restAboutLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #C2272F;
    }

    .restAboutValue {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
        white-space: nowrap;
    }

    .restLink, .restLink a {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-decoration-line: underline;
        color: #6A6B6B;
    }

    .restButtons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }

    .restButtons > div {
        padding: 24px 36px;
        width: 100%;
    }

    .restButtons > div > span {
        font-size: 20px;
        line-height: 24px;
    }

    .mobileButtons {
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        padding: 0 84px;
        gap: 32px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }


    .selectorItems {
        flex-direction: column;
        display: none;
        justify-content: center;
        align-items: flex-start;
        padding: 24px 32px;
        gap: 19px;

        position: absolute;
        background: #F9F9F9;
        box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);

        top: -15px;
    }

    .open {
        display: flex;
    }

    .selectorItem {
        padding: 10px 0;
        width: 280px;
        -webkit-tap-highlight-color: transparent;
    }

    .selectorTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

    .selector {
        flex-direction: column;
        align-items: center;
        position: relative;
    }

    .selectorItems > img {
        position: absolute;
        right: 32px;
        top: 32px;
    }

    .restTag {
        font-size: 18px;
        line-height: 133%;
    }
}