.container {
    margin: 52px 0 120px 0;
}

.title {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #414141;
    background: #F9F9F9;
    padding: 0 72px;
    z-index: 2;
}

.titleSub {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #A4A6A6;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 1.05rem;
    left: 0;
    z-index: 1;
}


.inner {
    margin-top: 52px;
    padding: 0 72px;
}

.description {
    display: flex;
    gap: 120px;
    align-items: center;
}

.typo {
    display: flex;
    flex-direction: column;
    gap: 36px;
    max-width: 600px;
    width: 600px;
}

.preHead {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A4A6A6;
    margin-bottom: -20px;
}

.head {
    font-style: normal;
    color: #414141;

    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
}

.head span {
    color: #C2272F;
}

.text {
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 133.8%;
}

.button {
    width: 50%;
}

.progress {
    opacity: 0.5;
    height: 2px;
    margin-top: 12px;
    transition: all .5s;
}

.progress_1 {
    background: linear-gradient(90deg, #C2272F, #C2272F 0%, #A4A6A6 0%, #A4A6A6);
}

.progress_2 {
    background: linear-gradient(90deg, #C2272F, #C2272F 15%, #A4A6A6 15%, #A4A6A6);
}

.progress_3 {
    background: linear-gradient(90deg, #C2272F, #C2272F 30%, #A4A6A6 30%, #A4A6A6);
}

.progress_4 {
    background: linear-gradient(90deg, #C2272F, #C2272F 45%, #A4A6A6 45%, #A4A6A6);
}

.progress_5 {
    background: linear-gradient(90deg, #C2272F, #C2272F 60%, #A4A6A6 60%, #A4A6A6);
}

.progress_6 {
    background: linear-gradient(90deg, #C2272F, #C2272F 75%, #A4A6A6 75%, #A4A6A6);
}

.progress_7 {
    background: linear-gradient(90deg, #C2272F, #C2272F 90%, #A4A6A6 90%, #A4A6A6);
}

.form {
    display: flex;
    margin-top: 52px;
    gap: 50px;
}

.formSteps {
    z-index: 2;
}

.steps {
    max-width: 380px;
    width: 380px;
    padding: 52px 48px;
    gap: 72px;
    box-sizing: border-box;
    background: #F9F9F9;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.stepTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #A4A6A6;
}

.stepList {
    margin-top: 48px;
    display: flex;
    flex-direction: column;
}

.step.active {
    color: #FFFFFF;
    background: #C2272F;
}

.step {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #6A6B6B;
    padding: 12px 20px;
}

.survey {
    z-index: 1;
    padding: 72px;
    background: #F9F9F9;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
    position: relative;
    width: 100%;
    box-sizing: border-box;
}

.block {
    display: none;
    width: 100%;
}

.block.active {
    display: block;
}

.common {
    gap: 72px 48px;
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: row dense;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
    "rest name"
    "rest phone"
    "waiter check";
}

.commonQuestionRest {
    grid-area: rest;
}

.commonQuestionName {
    grid-area: name;
}

.commonQuestionPhone {
    grid-area: phone;
}

.commonQuestionWaiter {
    grid-area: waiter;
}

.commonQuestionReceipt {
    grid-area: check;
}

.commonQuestion {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.commonAnswer {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.commonCheckbox {
    display: flex;
    gap: 12px;
}

.commonCheckbox span {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #000000;
}

.commonTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-transform: uppercase;
    color: #000000;
}

.input {
    border: none;
    background: rgba(164, 166, 166, 0.1);
    padding: 14px 24px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
    width: 100%;
    font-family: 'TT Norms Pro', serif;
}

.input::placeholder {
    font-family: 'TT Norms Pro', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A4A6A6;
}

.input:focus {
    outline: none;
}


.blockTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 35px;
    text-transform: uppercase;
    color: #000000;
}

.questions {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
}

.nextButton {
    margin-top: 72px;
    display: flex;
    justify-content: flex-end;
}

.nextWithBack {
    justify-content: space-between;
}

.back, .back span {
    align-items: center;
    gap: 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    cursor: pointer;
    display: flex;
}

.nextButton > div {
    max-width: 200px;
}

.finalButton > div {
    max-width: 240px;
}


@media (max-width: 768px) {
    .inner {
        padding: 0 32px;
    }

    .form {
        flex-direction: column;
    }

    .steps {
        width: 100%;
        max-width: 100%;
    }

    .stepTitle {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    .title:after {
        top: 45px;
    }

    .titleText {
        width: 65%;
        padding: 0 32px;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
    }

    .description {
        flex-direction: column;
        gap: 56px;
    }

    .typo {
        gap: 36px;
        width: 100%;

    }

    .image img {
        height: 535px;
        object-fit: cover;
        width: 100%;
    }

    .button {
        margin-top: 72px;
        width: 100%;
    }

    .button span {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .preHead {
        font-size: 16px;
    }

    .head {
        font-weight: 500;
        font-size: 32px;
        line-height: 133.8%;
    }

    .text {
        font-weight: 400;
        font-size: 32px;
        line-height: 133.8%;
    }

    .survey {
        padding: 52px 32px;
    }

    .common {
        display: flex;
        flex-direction: column;
    }

    .input, .textarea, .input::placeholder, .textarea::placeholder,
    input, textarea, input::placeholder, textarea::placeholder {
        font-size: 20px;
    }

    .input, .textarea, input, textarea {
        padding: 24px 24px;
    }

    .step {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }

    .nextButton > div:not(.back) > span {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
    }

    .back span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}
