.container {
    margin: 52px 0 0 0;
}

.title {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #414141;
    background: #F9F9F9;
    padding: 0 72px;
    z-index: 2;
}

.titleSub {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #A4A6A6;
    text-align: center;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 1.05rem;
    left: 0;
    z-index: 1;
}


.inner {
    position: relative;
    margin-top: 52px;
    min-height: 600px;
    height: 75vh;
    background-image: url("../../static/images/forms/table.jpg");
    background-size: cover;
    background-position: center;
}

.buttons {
    display: flex;
    position: absolute;
    top: 200px;
    gap: 85px;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.button {
    cursor: pointer;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
    padding: 24px 65px;
    background: #C2272F;
    box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.47);
}

.button span:nth-child(1) {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.button span:nth-child(2) {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FFFFFF;
}


@media (max-width: 768px) {
    .inner {
        padding: 0 32px;
    }

    .title:after {
        top: 45px;
    }

    .titleText {
        width: 65%;
        padding: 0 32px;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
    }

    .buttons {
        flex-direction: column;
        gap: 45px;
        position: relative;
        top: 80px;
    }

    .inner {
        height: 65vh;
        background-image: url("../../static/images/forms/table-mob.jpg");
    }

    .button {
        box-sizing: border-box;
        width: 100%;
    }

    .button span:nth-child(1) {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .button span:nth-child(2) {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
    }


}