
.container {
    padding: 72px;
    max-width: 1440px;
    margin: 70px auto 0;
}

.title {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    position: relative;

}

.preHead {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
}

.head {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    color: #414141;
    margin-top: 20px;
    margin-bottom: 0;
}

.title:before {
    content: '';
    width: calc(100% - 495px + ((100vw - 1440px)/ 2));
    height: 0.7px;
    position: absolute;
    background: #A4A6A6;
    left: calc(-72px - ((100vw - 1440px)/ 2));
    bottom: 16px;
}

.inner {
    display: grid;
    grid-template-columns: 470px 80px auto;
    padding: 55px 0;
}

.preview {
    padding: 0 28px;
    width: 414px;

}

.previewImage {
    width: 414px;
    height: 323px;
}

.previewImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.previewTextHolder {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    margin: 30px auto 0 auto;
    width: 374px;
}

.previewTitle {
    display: flex;
    grid-gap: 16px;
}

.previewTitleBullet {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
}

.previewTitleText {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #414141;
}

.previewText {
    font-size: 15px;
    line-height: 20px;
    color: #6A6B6B;
}

.previewButton {
    width: 369px;
}

.nextButton {
    position: relative;
}

.nextButton img {
    position: absolute;
    top: 215px;
}

.galleryInner {
    padding: 35px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 192px 192px;
    grid-gap: 15px;
}

.galleryImage {
    cursor: pointer;
}

.galleryImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media (max-width: 768px) {
    .container {
        padding: 0 32px;
        margin-top: 200px;
    }

    .title {
        align-items: flex-start;
    }

    .inner {
        grid-template-columns: 305px 1fr;
        gap: 27px;
    }

    .previewImage {
        height: 305px;
        width: 305px;
    }

    .preview {
        width: 100%;
        padding: 0;
    }

    .preview, .previewTextHolder, .previewButton {
        width: 100%;
    }

    .nextButton {
        display: none;
    }

    .gallery {
        display: flex;
        flex-direction: column;
    }

    .galleryInner {
        display: flex;
        flex-direction: column;
        overflow: hidden;
        height: calc((180px + 13px) * 3);
        padding: 0;
        gap: 13px;
        transition: all .3s;
    }

    .galleryImage {
        width: 188px;
        height: 180px;
        min-height: 180px;
    }

    .previewTitleBullet {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        display: none;
    }

    .previewImage {
        box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
    }

    .previewTitleText {
        letter-spacing: initial;
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;

        color: #000000;
    }

    .previewText {
        max-height: 100px;
        height: 100px;
        overflow: hidden;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
    }

    .galleryScroll {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }
}
