.container {
    display: flex;
    padding: 72px;
    justify-content: space-between;

    max-width: 1440px;
    margin: 50px auto 0;
}

.texts {
    display: flex;
    flex-direction: column;
    grid-gap: 55px;
    width: 428px;
    padding-right: 30px;
    box-sizing: border-box;
}

.title {
}

.preHead {
    font-size: 18px;
    line-height: 22px;

    letter-spacing: 0.13em;
    text-transform: uppercase;

    color: #C2272F;
}

.head {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #333333;
    margin-top: 15px;
    margin-bottom: 0;
}

.text {
    font-size: 15px;
    line-height: 20px;

    color: #414141;
}

.list {
    display: flex;
    flex-direction: column;
    grid-gap: 40px
}

.listItem {
    display: flex;
    grid-gap: 37px;
    cursor: pointer;
}

.listItemBullet {
}

.listItemText {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #000000;

}

.restaurant {
    width: 746px;
    padding: 35px 0;
}

.restaurantGallery {
    display: flex;
    justify-content: space-between;
}

.galleryPreview {
    width: 589px;
    height: 387.21px;
    box-shadow: 0px 4.28246px 14.9886px 5.35307px rgba(0, 0, 0, 0.2);
}

.galleryPreview img,
.galleryImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.galleryImage {
    width: 132px;
    height: 105px;
    box-shadow: 0px 3.96127px 13.8645px 4.95159px rgba(0, 0, 0, 0.2);
    cursor: pointer;
}

.galleryImages {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 18px 0px 18px 20px;
}

.restaurantInfo {
    display: grid;
    grid-template-columns: 245px auto 280px;
    margin-top: 50px;
    align-items: center;
}


.restaurantInfoItem {
    display: flex;
    flex-direction: column;
    grid-gap: 8px;
}

.restaurantInfoItem{
    text-decoration: none;
}

.restaurantInfoHead {
    font-size: 14px;
    line-height: 17px;
    color: #C2272F;
}

.restaurantInfoText {
    font-size: 20px;
    line-height: 24px;
    color: #414141;
    text-decoration: none;
}

.restaurantInfoSub, .restaurantInfoSub a {
    margin-top: 8px;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
}

.address {
}

.phone {
}

.button {
}

@media (max-width: 768px) {
    .container {
        padding: 0;
        flex-direction: column;
        margin-top: 150px;
    }

    .texts {
        padding: 0 32px;
        width: initial;
    }

    .text {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #414141;
    }

    .inner {
        margin-top: 32px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 26px;
    }

    .listItem {
        gap: 14px;
    }


    .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 32px;
        box-sizing: border-box;
    }


    .list {
        margin-top: 72px;
    }

    .galleryPreview {
        width: 100%;
        height: 476px;
    }

    .button {
        margin-top: 32px;
        padding-right: 32px;
        gap: 16px;
        display: flex;
        flex-direction: column;
    }

    .button > div {
        padding: 16px 22px;
    }

    .restaurantInfo {
        display: flex;
        flex-direction: column;
        gap: 27px;
        align-items: flex-start;
    }

    .restaurant {
        width: 100%;
        padding: 0;
    }

    .restaurantInfoHead {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #C2272F;
    }

    .restaurantInfoText {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;

        color: #3D3D3D;
    }

    .restaurantInfoSub {
        font-size: 16px;
    }

    .listItemText {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: right;
        text-transform: uppercase;
        letter-spacing: initial;

        color: #000000;
    }

    .galleryPreview {
        box-shadow: none;
    }
}
