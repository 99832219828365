.wrapper {
}

.category {
    margin-top: 20px;
}

.sectionTitle {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #414141;
    position: relative;
}

.sectionTitle:after {
    position: absolute;
    content: '';
    background: #A4A6A6;
    width: 100%;
    bottom: -14px;
    height: 1px;
    right: 0;
}

.sectionDescription {
    margin-top: 52px;
}

.sectionDescription h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 36px;
    letter-spacing: .15em;
    text-transform: uppercase;
    color: #414141;
    background: #f9f9f9;
    position: relative;
    z-index: 2;
}

.products {
    padding-right: 72px;
    margin-top: 45px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
}

.filters {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    flex-wrap: wrap;
}

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 15px;
    padding-right: 72px;
}

.grid img:nth-child(1) {
    grid-column: 2 span !important;
}

.grid img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all .3s;
    cursor: pointer;
}

.grid img:hover {
    transform: scale(1.05);
    transform-origin: center;
}

.filter {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px;
    gap: 8px;
    background: #F9F9F9;
    border: 1px solid #A4A6A6;
    cursor: pointer;
}

.filter:hover,
.filter.active {
    background: #414141;
    border: 1px solid #F9F9F9;
}

.filter:hover .filterText,
.filter.active .filterText {
    color: #F9F9F9;
}

.filter.active .filterIcon, .filter:hover .filterIcon {
    filter: brightness(0) grayscale(1) invert(1);
}

.filterText {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
}

.filterIcon {
    height: 24px;
    display: flex;
    align-items: center;
    filter: grayscale(1) invert(1);
}

.loader {
    height: 500px;
}

.filterDrop {
    display: flex;
    gap: 8px;
    align-items: center;
    height: 100%;
    padding: 8px;
    cursor: pointer;

}

.filterDrop .filterText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A4A6A6;
}

.filterDrop:hover .filterText {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6A6B6B;
}

@media (max-width: 768px) {
    .sectionTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .sectionTitle:after {
        display: none;
    }

    .category {
        padding: 0 32px;
        margin-top: 0;
    }

    .products {
        padding: initial;
        margin-top: 52px;
        grid-template-columns: 1fr 1fr;
    }

    .filters {
        display: grid;
        grid-template-columns: 1fr 100px;
        gap: 40px;
    }

    .filtersDropdownLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 133%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #414141;
    }

    .filtersDropdownLabel span:nth-child(2) {
        height: 32px;
    }

    .filtersDropdown {
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 8px 18px;
        gap: 1px;
        border: 1px solid #A4A6A6;
        position: relative;
    }

    .filtersDropdown.active {
        background: #414141;
    }

    .filtersDropdown.active .filtersDropdownLabel {
        color: #F9F9F9;
    }

    .filtersDropdown.active .filtersDropdownLabel img {
        filter: brightness(0) invert(1);
    }

    .filtersDropdownOptions {
        background: #F9F9F9;
        /* shadow */
        box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
        top: calc(100%);
        position: absolute;
        width: 100%;
        z-index: 2;
        border: 1px solid #A4A6A6;
    }

    .filter {
        border: none;
        width: 100%;
        box-sizing: border-box;
        padding: 24px 18px 18px;

        font-weight: 500;
        font-size: 16px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        line-height: 133%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #414141;
        gap: 24px;

        position: relative;
    }

    .filterIcon {
        width: 32px;
        height: 32px;
    }

    .filterIcon img {
        width: 32px;
    }

    .filter + .filter {
        border-top: 1px solid rgba(164, 166, 166, 0.1);
    }

    .filterDrop .filterIcon {
        width: 50px;
        height: 50px;
    }

    .filterDrop {
        padding: 0;
    }

    .filterDrop .filterIcon img {
        width: 18px;
    }

    .filter.active, .filter:hover {
        background: #F9F9F9;
        border: unset;
    }

    .filter.active .filterText, .filter:hover .filterText {
        color: #C2272F;
    }

    .filter.active .filterIcon, .filter:hover .filterIcon {
        filter: unset;
    }

    .filter.active:after, .filter:hover:after {
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50px;
        background: #C2272F;
        right: 30px;
        top: calc(50% - 5px);
        position: absolute;
    }

    .grid {
        grid-template-columns: 1fr 1fr;
        padding-right: 0;
    }
}

@media (min-width: 1800px) {
    .products {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}