.main {
    /*max-width: 1440px;*/
    margin: 0 auto;
}

.hero {
    width: 100vw;
    max-height: 100vh;
    max-height: -webkit-fill-available;
    background: #f9f9f9;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    position: relative;
    height: 100vh;
}

.hero .logo {
    margin-top: 90px;
    z-index: 5;
}

.hero .image {
    position: absolute;
    bottom: 0;
    max-height: 50vh;
    background-image: url("../static/images/main/hero.png");
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 55vh;
}


.hero .image:before {
    position: absolute;
    top: -30px;
    background-image: url("../static/images/main/hover.png");
    width: 100%;
    height: 100px;
    content: "";
}

.hero .btn {
    background: #F9F9F9;
    opacity: 0.7;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
    position: absolute;
    bottom: 110px;
    position: absolute;
    width: 96px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
}

.nav {
    position: absolute;
    right: 32px;
    top:37px;
}