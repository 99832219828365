.container {
    margin: 52px 0 120px 0;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.titleBack {
    background: #f9f9f9;
    z-index: 2;
    padding: 0 72px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    cursor: pointer;
}

.inner {
    margin-top: 52px;
    padding: 0 72px;
}

.infoParams {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 34px;
}

.head {
    margin-left: 110px;
    display: flex;
    gap: 90px
}

.titleBack span {
    height: 20px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    padding: 0 72px 0 82px;
    text-transform: uppercase;
    color: #414141;
    background: #f9f9f9;
    position: relative;
    z-index: 2;
    margin: 0;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 50%;
    z-index: 1;
}

.aboutLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #C2272F;
}

.aboutValue {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414141;
}

.aboutSect {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 225px;
}

.aboutOperating {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #6A6B6B;
}

.gallery {
    margin-top: 72px;
    display: grid;
    grid-template-columns: 1fr 1fr;
}



.galleryCol2 {
    box-sizing: border-box;
    padding: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 24px;
}

.galleryCol2 img,
.galleryCol1 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.buttons {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.infoPreview {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.text {
    margin-top: 52px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

@media (max-width: 768px) {
    .titleBack {
        display: none;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .titleText {
        padding: 0;
        margin: 0 32px;
    }

    .title:after {
        position: absolute;
        content: '';
        background: #A4A6A6;
        width: 100%;
        top: 50%;
        bottom: initial;
        height: 0.7px;
        right: 0;
    }

    .inner {
        display: flex;
        flex-direction: column;
        padding: 0 32px;
    }

    .head {
        display: flex;
        flex-direction: column;
        margin-left: 0;
        gap: 48px;
    }

    .infoParams {
        display: flex;
    }

    .aboutLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        color: #C2272F;
    }

    .aboutValue {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

    .buttons > div {
        padding: 24px;
    }

    .buttons > div > span {
        font-size: 24px;
    }

    .text, .infoPreview {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #414141;
    }

    .gallery {
        display: flex;
        flex-direction: column;
    }

    .galleryCol1 {
        height: 410px;
    }

    .galleryCol1 img {
        box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
    }

    .galleryCol2 {
        padding: 24px 0 0 0;
    }

    .galleryCol2 img {
        height: 217px;
        box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);

    }
}