
.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: -10;
    height: 100%;
    width: 100%;
}


.loaderSmall {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    z-index: 1;
    height: 30px;
    width: 30px;
}

.loader.loaderFullsize {
    width: 100vw;
    height: 100vh;
}

.ldsRing {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.ldsRing div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 3px;
    border: 3px solid #C2272F;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #C2272F transparent transparent transparent;
}
.ldsRing div:nth-child(1) {
    animation-delay: -0.45s;
}
.ldsRing div:nth-child(2) {
    animation-delay: -0.3s;
}
.ldsRing div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.ldsRingWhite div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 3px;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border: 3px solid #f9f9f9 ;
    border-color: #f9f9f9 transparent transparent transparent ;
}