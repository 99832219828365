.container {
    margin: 34px 0 160px 0;
}

.title,
.innerTitle,
.innerTitle4 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    margin: 0 0 52px;
}

.titleBack {
    background: #f9f9f9;
    z-index: 2;
    padding: 0 132px 0 72px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    cursor: pointer;
}


.titleText {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
}

.titleText h1,
.titleText h2 {
    margin: 0;
    background: #f9f9f9;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
}

.titleText h1 {
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    color: #414141;
}

.titleText h2 {
    font-size: 32px;
    line-height: 36px;
    letter-spacing: 0.065em;
    color: #000000;
}

.titleText:after {
    content: '';
    flex-grow: 1;
    height: 0.7px;
    margin: 0 0 0 72px;
    background: #A4A6A6;
    z-index: 1;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 72px;
}

.innerContainer1 {
    margin: 0 auto 175px;
}

.innerContainer2 {
    margin: 0 auto 135px;
}

.innerContainer3 {
    margin: 0 auto 140px;
}

.innerContainer4 {
    margin: 0 auto 155px;
}

.innerContainer5 {
    margin: 0 auto 175px;
}

.imageBlock1,
.imageBlock2,
.imageBlock3 {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.imageBlock1 {
    flex-direction: row-reverse;
}

.text1,
.text2 {
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.text1 {
    padding: 0 0 120px 10px;
}

.text1 p,
.text2 p {
    margin: 0;
    font-style: normal;
    font-weight: 400;
    color: #414141;
}

.text1 p {
    letter-spacing: 0.07em;
    max-width: 665px;
}

.text1 p:first-of-type  {
    max-width: 685px;
    font-size: 24px;
    line-height: 40px;
    text-transform: uppercase;
    margin: 0 0 35px;
}

.text1 p:first-of-type span {
    color: #151515;
    font-weight: 500;
}

.text1 p:last-of-type {
    max-width: 595px;
    font-size: 18px;
    line-height: 24.12px;
    letter-spacing: normal;
}

.img1 img {
    width: 526px;
    height: 580px;
}

.text2 {
    padding: 105px 10px 0 0;
}

.text2 p:first-child {
    margin: 0 0 30px;
    color: #C2272F;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: 0.09em;
}

.text2 p:last-child {
    max-width: 640px;
    font-size: 20px;
    line-height: 32px;
    text-transform: uppercase;
    color: #414141;
    letter-spacing: 0.076em;
}

.img2 {
    margin: -105px 0 0;
}

.img2 img {
    width: 526px;
    height: 627px;
}

.imageBlock3 {
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 35px 0 0;
}

.img3 {
    margin: 0 0 65px;
}

.img3 img {
    width: 526px;
    height: 657px;
}

.text3 {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 20px 10px 0 0;
}

.text3InnerBlock {
    margin: 0 0 90px;
}

.text3InnerBlock p {
    margin: 0;
}

.text3InnerBlock p:first-of-type {
    display: flex;
    align-items: center;
    margin: 0 0 20px;
}

.text3InnerBlock p:first-of-type span:first-of-type {
    align-self: flex-start;
    font-size: 24px;
    line-height: 24px;
    margin: 0 45px 0 0;
    color: #C2272F;
    white-space: nowrap;
}

.text3InnerBlock p:first-of-type span:last-of-type {
    max-width: 585px;
    font-size: 20px;
    line-height: 28px;
    color: #1C1C1C;
    text-transform: uppercase;
    letter-spacing: 0.09em;
}

.text3InnerBlock p:last-of-type {
    max-width: 580px;
    padding: 0 0 0 90px;
    font-size: 18px;
    line-height: 23.5px;
    color: #000000;
}

.imageBlock4 {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 35px 0 0;
}

.img4 {
    margin: 0 60px 0 0;
}

.img4 img {
    width: 549px;
    height: 669px;
}

.imageBlock5 {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px 0 0;
}

.text5 {
    max-width: 635px;
    padding: 20px 0 0;
    margin: 0 0 136px;
}

.text5 p {
    margin: 0;
}

.text5 p:not(:last-of-type) {
    margin: 0 0 48px;
}

.text5 p:first-of-type {
    color: #3b3b3b;
    font-size: 20px;
    line-height: 28px;
    text-transform: uppercase;
    letter-spacing: 0.07em;
}

.text5 p:not(:first-of-type) {
    color: #1b1b1b;
    font-size: 20px;
    line-height: 26.5px;
}

.text5 p span {
    color: #151515;
    font-weight: 500;
}

.text5 p:last-of-type {
    max-width: 500px;
}

/* .img5 {
    padding: 0 0 0 25px;
} */

.img5 img {
    width: 660px;
    height: 392px;
}

.imageBlock6 {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    padding: 51px 0 0;
}

.rightArrow {
    align-self: flex-end;
    margin: 0 20px 0 23px;
}

.rightArrow img {
    width: 48px;
    height: 50px;
}

.imageBlock7 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 515px;
}

.text7 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1px 0 0;
    margin: 0 0 48px;
    width: 735px;
}

.text7 p  {
    margin: 0;
}

.text7 p:first-of-type  {
    color: #3c3c3c;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    margin: 0 0 13px;
}

.text7 p:nth-child(2) {
    color: #909090;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.09em;
    margin: 0 0 35px;
}

.text7 p:last-of-type {
    color: #3c3c3c;
    font-size: 20px;
    line-height: 24px;
    font-weight: 400;
}

.imgGroup {
    display: flex;
    flex-wrap: wrap;
}

.imgGroup div {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #b1b1b1;
    margin: 0 42px 0 0;
}

.imgGroup img {
    width: 231px;
    height: 235px;
    object-fit: cover;
    object-position: top;
}

.imgGroup p {
    margin: 0;
    text-transform: uppercase;
    color: #6d6d6d;
    font-size: 16px;
    line-height: 20px;
    padding: 18px 0;
    letter-spacing: 0.01em;
}

.imageBlockWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.imageBlock9 {
    padding: 70px 0 0 3px;
}

.text9 {
    margin: 0 0 100px;
}

.text9 p {
    margin: 0;
    color: #000704;
    font-size: 20px;
    line-height: 28px;
}

.text9 p span {
    color: #000000;
    font-weight: 500;
}

.text9 p:not(:last-of-type) {
    margin: 0 0 25px;
    width: 690px;
}

.text9 p:last-of-type {
    width: 650px;
}

.sliderBlock {
    display: none;
}

.imageBlock10 {
    display: flex;
    flex-direction: column;
    padding: 38px 0 0;
}

.topImage {
    align-self: flex-end;
    padding: 0 20px 0 0;
    margin: 0 0 70px;
}

.bottomImage {
    align-self: flex-end;
}

.rightArrow9 {
    display: none;
}

.activeImage {
    display: none;
}

.koreLink {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 133%;
    text-decoration-line: underline;
    color: #6A6B6B;
    cursor: pointer;
}

.imageBlock12 {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    padding: 55px 0 0 4px;
}

.text12 {
    padding: 41px 0 0 80px;
}

.text12 p {
    margin: 0;
}

.text12 p:nth-child(1) {
    color: #000000;
    font-size: 20px;
    line-height: 27px;
    max-width: 649px;
    margin: 0 0 26px;
}

.text12 p:nth-child(2) {
    color: #000000;
    font-size: 20px;
    line-height: 27px;
    max-width: 649px;
    margin: 0 0 70px;
}

.text12 p:nth-child(3) {
    color: #000000;
    font-size: 20px;
    line-height: 31px;
    text-transform: uppercase;
    letter-spacing: 0.15em;
    max-width: 700px;
    margin: 0 0 38px;
}

.text12 p:nth-child(4) {
    color: #C2272F;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.11em;
}

@media (max-width: 768px) {
    .container {
        margin: 32px 0 120px 0;
    }

    .innerContainer {
        padding: 0;
    }

    .innerContainer1 {
        margin: 0 auto 88px;
    }

    .innerContainer2,
    .innerContainer3 {
        margin: 0 auto 104px;
    }

    .innerContainer4 {
        margin: 0 auto 77px;
    }

    .innerContainer5 {
        margin: 0 auto 107px;
    }

    .innerContainer6 {
        margin: 0 auto 165px;
    }

    .title {
        flex-direction: column;
        align-items: center;
        margin: 0 0 67px;
    }

    .innerTitle {
        margin: 0 0 40px;
    }

    .titleBack {
        align-self: flex-start;
        padding: 0 0 0 16px;
        font-size: 15px;
        line-height: 19px;
        margin: 0 0 23px;
        letter-spacing: 0.085em;
    }

    .titleText {
        align-self: stretch;
        margin: 0;
    }

    .titleText h1 {
        padding: 0 32px;
        font-size: 40px;
        line-height: 46px;
        letter-spacing: 0.085em;
    }

    .titleText h2 {
        padding: 0 32px;
        font-size: 32px;
        line-height: 48px;
        letter-spacing: 0.06em;
    }

    .titleText::after,
    .titleText::before {
        content: '';
        flex-grow: 1;
        height: 1px;
        margin: 0;
        background: #A4A6A6;
        z-index: 1;
    }

    .imageBlock1,
    .imageBlock2,
    .imageBlock3 {
        flex-direction: column;
    }

    .text1 {
        padding: 0 30px;
        margin: 0 0 62px;
    }

    .text1 p:first-of-type {
        margin: 0 0 33px;
    }

    .text1 p:last-of-type {
        max-width: 570px;
        font-size: 24px;
        line-height: 32px;
        letter-spacing: 0;
    }

    .img2, .img1 {
        width: 100%;
        box-sizing: border-box;
    }

    .img1 img {
        width: 100%;
        height: 425px;
        object-fit: cover;
    }

    .text2 {
        padding: 0 32px;
        margin: 52px 0 50px;
        max-width: initial;
    }

    .text2 p:first-of-type {
        margin: 0 0 25px;
    }

    .img2 {
        margin: 0;
    }

    .img2 img {
        width: 100%;
        height: 391px;
        object-fit: cover;
    }

    .imageBlock3 {
        padding: 0;
    }

    .text3 {
        padding: 20px 32px 0;
        margin: 0 0 37px;
    }

    .text3InnerBlock {
        margin: 0 0 51px;
    }

    .text3InnerBlock p:first-of-type {
        display: flex;
        align-items: center;
        margin: 0 0 18px;
    }

    .text3InnerBlock p:first-of-type span:first-of-type {
        margin: 0 25px 0 0;
    }

    .text3InnerBlock p:first-of-type span:last-of-type {
        line-height: 32px;
    }

    .text3InnerBlock p:last-of-type {
        padding: 0 0 0 70px;
        font-size: 22px;
        line-height: 32px;
        letter-spacing: 0.047em;
    }

    .img3 {
        margin: 0;
    }

    .img3 img {
        width: 586px;
        height: 395px;
        object-fit: cover;
    }

    .innerTitle4 {
        margin: 0 0 30px;
    }

    .imageBlock4 {
        padding: 0;
    }

    .img4 {
        margin: 0;
    }

    .img4 img {
        width: 586px;
        height: 319px;
        object-fit: cover;
    }

    .text5 {
        padding: 25px 29px 0;
        margin: 0 0 87px;
    }

    .text5 p:last-of-type {
        max-width: 100%;
    }

    .text5 p:not(:last-of-type) {
        margin: 0 0 42px;
    }

    .text5 p:first-of-type {
        font-size: 24px;
        line-height: 32px;
    }

    .text5 p:not(:first-of-type) {
        font-size: 24px;
        line-height: 32px;
    }

    .img5 {
        padding: 0;
    }

    .img5 img {
        width: 586px;
        height: 392px;
        object-fit: cover;
    }

    .imageBlock6 {
        padding: 6px 32px 0;
        position: relative;
        max-width: 100%;
        overflow: hidden;
    }

    .img6 img {
        width: 328px;
        height: 382px;
        object-fit: cover;
    }

    .rightArrow {
        margin: 0;
        position: absolute;
        top: 155px;
        right: 60px;
    }

    .rightArrow img {
        display: block;
        width: 48px;
        height: 50px;
    }

    .text7 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 41px 0 0;
        width: auto;
    }

    .text7 p:first-of-type  {
        font-size: 24px;
        line-height: 28px;
        margin: 0 0 13px;
    }

    .text7 p:nth-child(2) {
        margin: 0 0 30px;
    }

    .text7 p:last-of-type {
        font-size: 24px;
        line-height: 28px;
    }

    .imageBlock7 {
        display: block;
        min-height: auto;
    }

    .imgGroup {
        display: none;
        flex-wrap: nowrap;
        position: absolute;
        top: 43px;
        left: 423px;
    }

    .imgGroup img {
        width: 242px;
        height: 246px;
    }

    .imgGroup p {
        font-size: 19px;
        line-height: 23px;
        padding: 20px;
        letter-spacing: 0.09em;
    }

    .imageBlockWrapper {
        position: relative;
        max-width: 100%;
        overflow: hidden;
    }

    .imageBlock9 {
        display: flex;
        flex-direction: column-reverse;
        padding: 0 29px;
    }

    .staticImage {
        display: none;
    }

    .activeImage {
        display: block;
    }

    .activeImage img {
        width: 333px;
        height: 382px;
        object-fit: cover;
    }

    .text9 {
        margin: 0;
        padding: 40px 0 0;
    }

    .text9 p {
        font-size: 24px;
        line-height: 33px;
    }

    .text9 p:not(:last-of-type) {
        margin: 0 0 35px;
        width: auto;
    }

    .text9 p:last-of-type {
        width: auto;
    }

    .rightArrow9 {
        display: block;
        position: absolute;
        left: 369px;
        top: 172px;
    }

    .rightArrow9 img {
        width: 48px;
        height: 50px;
    }

    .imageBlock10 {
        display: none;
    }

    .sliderBlock {
        display: flex;
        flex-direction: row;
        position: absolute;
        top: 8px;
        left: 425px;
    }

    .topImage,
    .bottomImage {
        padding: 0;
        margin: 0;
    }

    .topImage {
        align-items: flex-start;
        margin: 0 10px 0 0;
    }

    .topImage img,
    .bottomImage img {
        width: 255px;
        height: 269px;
        object-fit: cover;
    }

    .imageBlock12 {
        padding: 10px 0 0;
        flex-direction: column-reverse;
    }

    .text12 {
        padding: 48px 28px 0 32px;
    }

    .text12 p {
        margin: 0;
    }

    .text12 p:nth-child(1) {
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 35px;
    }

    .text12 p:nth-child(2) {
        font-size: 24px;
        line-height: 32px;
        margin: 0 0 97px;
    }

    .text12 p:nth-child(3) {
        font-size: 24px;
        line-height: 36px;
        margin: 0 0 38px;
    }

    .text12 p:nth-child(4) {
        color: #C2272F;
        font-size: 24px;
        line-height: 28px;
        letter-spacing: 0.11em;
    }

    .img12 img {
        width: 586px;
        height: 504px;
        object-fit: cover;
    }
}
