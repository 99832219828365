.modal {
    width: 538px;
    background-color: #FFFFFF;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.20);
    margin: 0 auto;
    align-self: center;
    z-index: 11;
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;

    transition: all .4s ease-in-out;

    opacity: 0;
}

@-webkit-keyframes trans-in {
    0% {transform: translateY(100px);}
    100% {transform: translateY(0px);}
}
@keyframes trans-in {
    0% {transform: translateY(100px);}
    100% {transform: translateY(0px);}
}

.modalOpen {
    opacity: 1;
    animation: trans-in .4s forwards;
}

.cross {
    width: 65px;
    height: 65px;
    cursor: pointer;
    position: absolute;
    right: 15px;
    top: 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
}

.cross img {
    width: 60px;
    height: 60px;
}

.cross:hover {
    opacity: 0.5;
}

.inner {
    padding: 63px 45px 39px;
}

.title {
    color: #414141;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.82px;
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 24px;
}

.text {
    color: #414141;
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    margin-bottom: 20px;
    padding: 0 20px;
}

.textColor {
    color: #C2272F;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    margin-top: 24px;
    margin-bottom: 15px;
    padding: 0 10px;
}

.list {
    margin: 0;
    padding: 0;
    padding-left: 30px;
    margin-bottom: 24px;
}

.listItem {
    color: #414141;
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
}

.textForm {
    width: 313px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 9px;
    color: #A4A6A6;
    text-align: center;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
}

.textSuccess {
    text-align: center;
}

.input {
    width: 100%;
    padding: 15px 24px;
    background-color: rgba(106, 107, 107, 0.1);
    font-size: 14px;
    line-height: 17px;
    color: #333333;
    box-sizing: border-box;
    border: none;
    font-family: 'TT Norms Pro', sans-serif;
    outline: none;
}

.input::placeholder {
    font-family: 'TT Norms Pro', sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
}

.row {
    margin-bottom: 18px;
}

.rowLast {
    margin-bottom: 24px;
}

.rowColumn {
    display: flex;
    column-gap: 48px;
}

@media (max-width: 768px) {
    .modal {
        top: 50px;
        width: calc(100% - 64px);
        box-sizing: border-box;
        position: relative;
        margin-bottom: 150px;
    }

    .inner {
        padding: 80px 32px 48px;
    }

    .title {
        font-size: 32px;
        line-height: 135%;
        letter-spacing: 2.72px;
        margin-bottom: 48px;
    }

    .text {
        text-align: left;
        font-size: 24px;
        margin-bottom: 40px;
        padding: 0;
    }

    .textColor {
        font-size: 24px;
        margin-top: 70px;
        margin-bottom: 35px;
        padding: 0;
    }

    .list {
        padding-left: 25px;
        margin-bottom: 72px;
    }

    .listItem {
        font-size: 24px;
    }

    .textForm {
        width: 400px;
        margin-bottom: 18px;
        font-size: 18px;
    }

    .textSuccess {
        font-size: 24px;
    }

    .input {
        padding: 18px 24px;
        font-size: 20px;
        line-height: 1;
    }

    .input::placeholder {
        font-size: 20px;
        line-height: 1;
    }

    .row {
        margin-bottom: 24px;
    }

    .rowColumn {
        flex-direction: column;
        column-gap: auto;
        row-gap: 24px;
    }
}
