.button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 16px 8px 24px;
    gap: 8px;
    background: #414141;
    cursor: pointer;
}

.buttonDisabled {
    opacity: 0.5;
    cursor: not-allowed;
}


.progress {
    opacity: 0.8;
}

.buttonDiscount {
    background: #C2272F;
    color: #fff;
    padding: 8px 8px 8px 8px;
}

.buttonCounter {
    background: #C2272F;
    color: #fff;
    padding: 8px 16px 8px 16px;
    gap: 14px
}

.discount {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 0.13em;
    text-decoration-line: line-through;

    color: #FFFFFF;

    opacity: 0.5;
}

.price {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
}

.minus, .plus {
}

.unavailable {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #C2272F;
}

.buttonInCart {
    background: #C2272F;
    padding: 12px 28px;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-transform: uppercase;
    max-height: 46px;
    color: #FFFFFF;
    box-sizing: border-box;
}


@media (max-width: 768px) {
    .mobileLabel {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;
    }

    .mobilePrice {
    }

    .mobileDiscount {
        display: flex;
        gap: 18px;
        align-items: center;
    }

    .mobileDiscountText {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 133%;
        letter-spacing: 0.05em;
        text-decoration-line: line-through;
        text-transform: uppercase;
        color: #C2272F;
    }

    .mobilePriceText {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .mobileDiscount .mobilePriceText {
        color: #C2272F;
    }

    .mobilePlus {
    }


    .button {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 20px 16px;
    }

    .buttonInCart {
        max-height: initial;
        position: relative;
    }

    .buttonCounter {
        padding: 18px 40px;
        display: flex;
        gap: 30px;
        justify-content: space-between;
        text-align: center;
        max-width: 80%;
        margin-left: 20%;
    }

    .addButtonAnimation {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #C2272F;
        top: 0;
        left: 0;
        z-index: 9999;
      }

    .price {
        font-size: 32px;
    }

    .plus, .minus {
        padding: 4px 15px;
    }

    .plus img, .minus img {
        width: 40px;
    }

    .unavailable {
        font-size: 24px;
        padding: 20px;
    }
}