.modal {
    padding: 48px;
    width: 1142px;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    align-self: center;
    z-index: 11;
    position: absolute;
    left: 0;
    right: 0;

    transition: all .4s ease;

    opacity: 0;
    animation: trans-in .4s backwards;
}

@-webkit-keyframes trans-in {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes trans-in {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateY(0px);
    }
}

.modalOpen {
    opacity: 1;
    animation: trans-in .4s forwards;
}

.cross {
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 15px;
}

.title {
}

.map {
    width: 485px;
    height: 418px;
    overflow: hidden;
}

.map iframe {
    border: none;
    height: calc(100% + 150px);
    width: 100%;
    margin-top: -70px;
}

.address {
    width: 100%;
    display: grid;
    grid-template-columns: auto 485px;
    grid-gap: 68px;
}

.addressSelector {
    padding: 0px 0 0px 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    box-sizing: border-box;
}

.delivery {
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    height: 100%;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.titleText {
    font-weight: 500;
    font-size: 40px;
    line-height: 33px;
    text-align: right;
    letter-spacing: 0.085em;
    text-transform: uppercase;
    color: #414141;
}

.titleChangeType {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    text-align: right;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #A4A6A6;

    cursor: pointer;
}

.titleChangeType:hover {
    color: #6A6B6B;
}

.selector {
    margin-top: 52px;
}

.selectorText {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414141;
}

.selectorInput {
    margin-top: 24px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
}

.selectorInputHolder {
    position: relative;
}

.selectorAddressContainer {
    width: 100%;
}

.highlightAddressContainer {
    background: none;
    font-weight: initial;
}

.selectorAddressContainer span {
    color: #414141 !important;
}

.selectorAddressSuggestion {
    font-size: 14px;
    text-align: left;
    width: 100%;
    border: none;
}

.selectorAddressInput {
    border: none;
    background: rgba(164, 166, 166, 0.1);
    padding: 14px 24px;
    width: 100%;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.selectorAddressInput:focus {
    outline: none;
}

.selectorButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    gap: 16px;
    background: #C2272F;
    right: 0;
    top: 0;
    cursor: pointer;
}

.selectorButton span {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #F9F9F9;
}

.result {
}

.resultSummaryIcon div {
    width: 10px;
    height: 10px;
    border-radius: 50px;
}

.resultSummaryIconGreen {
    background: #A4CFAE;
}

.resultSummaryIconYellow {
    background: #d5d371;
}

.resultSummaryIconOrange {
    background: #ea7070;
}

.resultDescription {
    margin-top: 24px;
    margin-left: 35px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 32px 60px;
    grid-template-areas:
    "price payment"
    "time payment";
}

.resultPrice {
    grid-area: price;
}

.resultTime {
    grid-area: time;
}

.resultPayment {
    grid-area: payment;
}

.resultLabel {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    margin-bottom: 8px;
    color: #414141;
}

.resultValues {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.resultValue {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #6A6B6B;
}

.resultSummary {
    display: flex;
    gap: 24px;
    align-items: center;
}

.resultSummaryText {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #414141;
}

.conditions {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    text-transform: uppercase;
    cursor: pointer;
    color: #414141;
}

.selectorError {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
}

.selectorErrorIcon {
}

.selectorErrorText {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #C2272F;
}

.pickup {
    margin-top: 52px;
}

.pickupSelector {
}

.pickupText {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.pickupSelectorText {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: #414141;
    margin-top: 52px;
}

.pickupSelectorInfo {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #C2272F;
    margin-top: 32px;
}

.pickupSelectorItems {
    margin-top: 40px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 35px;
}

.pickupSelectorItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 18px;
    cursor: pointer;
}

.pickupSelectorIcon {
    filter: grayscale(1);
    opacity: 0.5;
}

.pickupSelectorItem:hover .pickupSelectorItemText,
.pickupSelectorItem.active .pickupSelectorItemText {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #414141;
}

.pickupSelectorItem:hover .pickupSelectorIcon,
.pickupSelectorItem.active .pickupSelectorIcon {
    filter: grayscale(0);
    opacity: 1;
}

.pickupSelectorItemText {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #A4A6A6;
}

.bsClearfix {
    background: #A4A6A6;
    opacity: 0.5;
    border-radius: 30px;
    width: 190px;
    height: 5px;
    left: 0;
    right: 0;
    top: 24px;
    margin: 2px auto;
}

@media (max-width: 768px) {
    .address {
        display: flex;
        flex-direction: column;
        padding: 72px 32px 0;
        box-sizing: border-box;
        gap: 64px;
    }

    .conditions {
        margin-top: 24px;

        font-size: 16px;
    }

    .map {
        width: initial;
        height: 588px;
    }

    .selectorText {
        font-size: 24px;
    }

    .resultSummary {
        gap: 20px;
    }

    .resultSummaryIcon div {
        width: 16px;
        height: 16px;
    }
    .resultSummaryText {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
    }

    .resultDescription {
        display: flex;
        flex-direction: column;
        gap: 36px
    }

    .resultLabel {
        font-size: 18px;
        margin-bottom: 12px;
        line-height: 133%;
    }

    .resultValue {
        font-size: 20px;
    }

    .resultValues {
        gap: 20px
    }

    .selectorAddressInput {
        font-size: 18px;
    }

    .selectorAddressSuggestion {
        font-size: 18px;
    }

    .pickupText {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

    .pickupSelectorInfo {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #C2272F;
    }

    .pickupSelectorItem:hover .pickupSelectorItemText,
    .pickupSelectorItem.active .pickupSelectorItemText,
    .pickupSelectorItemText {
        font-size: 20px;
        line-height: 24px;
    }

}
