.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px 0px;
    width: 270px;
    align-items: flex-start;
    position: sticky;
    top: 99px;
}

.menuHeader {
    display: grid;
    grid-template-columns: 38px 1fr;
    grid-gap: 16px;
    margin-bottom: 42px;
    padding: 0px 0 0px 60px;
}

.menuHeaderIcon {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    text-align: right;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
    white-space: nowrap;
}

.menuHeaderText {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #414141;
}

.menuHeaderArrow, .menuHeaderArrow img{
    height: 32px;
}

.menuInner {
    display: flex;
    flex-direction: column;
    grid-gap: 14px;
    width: 100%;
}

.menuInnerItem {
    display: flex;
    padding: 5px 0 5px 60px;
    text-decoration: none;
}

.menuInnerItem.active, .menuInnerItem:hover {
    background: #C2272F;
    cursor: pointer;
}

.menuInnerItem.active span, .menuInnerItem:hover span {
    color: #fff;
    cursor: pointer;
}

.menuInnerItem span {
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #414141;
}

.menuInnerItem.active span {
    color: #fff;
}

.menuOnline {
    border-top: 1px solid #BEBEBE;
    padding-top: 15px;
}

.menuOnline .menuInnerItem {
    display: flex;
    white-space: nowrap;
    gap: 12px;
}

.menuOnline span {
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    /* identical to box height, or 19px */
    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* grey */
    color: #414141;
}

.menuOnline .menuInnerItem:hover img,
.menuOnline .menuInnerItem.active img {
    filter: brightness(0) invert(1);
}

@media (max-width: 768px) {

    .menu {
        padding: 0;
        width: initial;
        align-items: center;
        position: relative;
        top: 0px;
    }

    .menuHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 24px 72px;
        background: #F9F9F9;
        margin-bottom: initial;
        border: 1px solid #A4A6A6;
        position: relative;
    }

    .menuHeader.fixed {
        border: 1px solid #F9F9F9;
    }

    .menuInner {
        display: none;
        padding: 22px 0;
        max-height: 75vh;
        z-index: 5;
        position: absolute;
        top: calc(100% + 12px);
        background: #F9F9F9;
        box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
        width: 100%;
        overflow: scroll;
    }

    .menuInnerItem {
        width: 100%;
        padding: 15px 0;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    .menuInnerItem span {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        color: #414141;
    }

    .open {
        display: block;
    }

    .clearfix {
        height: 75px;
        min-height: 75px;
    }

    .fixed {
        transition: all .2s;
        position: fixed;
        z-index: 9;
        box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
        animation: ffs .2s ease-in forwards;
    }

    @keyframes ffs {
        0% {
            transform: translateY(-100px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    @-webkit-keyframes ffs {
        0% {
            transform: translateY(-100px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    .menuOnline {
        padding: 0;
        border: none;
    }

    .menuOnline span {
        font-style: normal;
        font-weight: 500;
        line-height: 19px;
        text-transform: uppercase;
        color: #414141;
    }
}