.containerFixed {
    top: 0;
    position: fixed;
    height: 80px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    transform: translateY(-100px);
}

.up {
    display: flex;
    gap: 12px;
    align-items: center;
    margin-right: 35px;
    cursor: pointer;
}

.up span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* 2 light grey */
    color: #A4A6A6;

}

.navFixed {
    background: rgba(249, 249, 249, 0.8);
    transition: all .2s;
    position: fixed;
    z-index: 10;
    /*box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);*/

    animation: ffs .2s ease-in forwards;
}

@keyframes ffs {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0px);
    }
}

@-webkit-keyframes ffs {
    0% {
        transform: translateY(-100px);
    }
    100% {
        transform: translateY(0px);
    }
}

.container {
    height: 99px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
}

.logo {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logo img + img {
    margin-left: 50px;
}

.menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    grid-gap: 48px;

}

.menuItem {
    font-family: 'TT Norms Pro', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #414141;
    cursor: pointer;
}

.menuItemPhone {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 8px;
    text-decoration: none;

}

.menuItemPhone span {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    color: #6A6B6B;
}

.buttons {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding: 0px 87px 0 60px;
    grid-gap: 30px;
}

.buttonsDelivery {
    padding: 0px 87px 0 0;
}

.buttonsItem {
    cursor: pointer;
    position: relative;
}

.searchIcon {
    filter: grayscale(1) brightness(1.5) invert(1);
}

.searchIcon:hover, .searchIcon.active {
    filter: unset;
}

.search {
    width: 100%;
    margin-left: 60px;
    background: #F9F9F9;
    border: 1px solid #A4A6A6;
    box-sizing: border-box;
    display: flex;
    padding: 6px 9px;
}

.search img {
    margin-top: -3px;
    cursor: pointer;
}

.search input {
    width: 100%;
    background: #F9F9F9;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414141;
    box-sizing: border-box;
}

.search input:focus {
    outline: none;
}

.withSearch {
    padding: 0 87px 0 20px;
}

.cartButton {
    position: relative;
}

.cartButton span {
    position: absolute;
    background: #C2272F;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    right: 0;
    width: 15px;
    height: 15px;
    bottom: 0;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media (max-width: 768px) {
    .container {
        align-items: center;
        height: 95px;
    }

    .buttons {
        gap: 24px;
        padding: 0 32px;
    }

    .innerToggle {
        position: absolute;
        right: 32px;
        top: 37px;
    }

    .inner {
        background: #F9F9F9;
        box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
        padding: 110px 102px 52px 64px;
        max-width: 437px;
        box-sizing: border-box;
        right: 0;
        top: 0;
        position: fixed;
        z-index: 11;
        height: 100%;
        transform: translateX(100%);
        transition: all .3s;
    }

    .menu {
        align-items: flex-start;
    }

    .inner.open {
        transform: translateX(0);
    }

    .routes {
        display: flex;
        flex-direction: column;
        gap: 72px
    }

    .route {
        display: flex;
        gap: 24px;
        align-items: center;
    }

    .deliveryRoutes {
        display: flex;
        flex-direction: column;
        gap: 30px
    }

    .deliveryRoute {
        display: flex;
        gap: 24px;
        align-items: center;
        text-decoration: none;
    }

    .routeIcon {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        letter-spacing: 0.13em;
        text-transform: uppercase;
        color: #C2272F;
    }

    .routeText.active {
        color: #C2272F;
    }

    .routeText {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #414141;
    }

    .deliveryRoute .routeText {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.1em;
        text-transform: initial;
        color: #6A6B6B;

    }

    .deliveryRoute:nth-child(2) .routeText {
        letter-spacing: initial;
    }

    .menu {
        margin-top: 52px;
        border-top: 1px solid #A4A6A6;
        display: flex;
        flex-direction: column;
        gap: 64px;
    }

    .menuItem {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #6A6B6B;
        white-space: nowrap;
    }

    .menuItem.active {
        color: #C2272F;
    }

    .mobile .container {
        background: #f9f9f9;
    }


    .fixed {
        top: 0;
        transition: all .2s;
        position: fixed;
        z-index: 10;
        box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
        animation: ffs .2s ease-in forwards;
    }

    @keyframes ffs {
        0% {
            transform: translateY(-100px);
        }
        100% {
            transform: translateY(0px);
        }
    }

    @-webkit-keyframes ffs {
        0% {
            transform: translateY(-100px);
        }
        100% {
            transform: translateY(0px);
        }
    }


    .clearfix {
        height: 99px;
        min-height: 99px;
    }

    .cartButton.show {
        transform: translateY(0px);
    }

    .cartButton {
        z-index: 12;
        transform: translateY(200px);
        transition: all .3s;
        position: fixed;
        bottom: 50px;
        right: 50px;
        width: 100px;
        height: 100px;
        background: #F9f9f9;
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
        border-radius: 50px;
    }

    .cartButton span {
        top: 50%;
        left: 50%;
        font-size: 18px;
        width: 24px;
        height: 24px;
    }

    .cartButton img {
        height: 50px;
    }

    .mobsearch {
        position: absolute;
        top: 100px;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 11;
    }

    .mobsearch input {
        box-sizing: border-box;
        background: #F9F9F9;
        box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
        -webkit-appearance: none;
        padding: 18px 24px;
        width: calc(100% - 64px);
        border: none;
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #414141;
        z-index: 11;
    }

    .mobsearch input::placeholder {
        font-size: 18px;
    }

    .mobsearchIcon {
        filter: grayscale(1) brightness(1.5) invert(1);
    }
    .mobsearchIcon.active {
        filter: unset;
    }
}