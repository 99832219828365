.card {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    width: 100%;
}

.cardHeader {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 18px;
}

.cardTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #414141;
}

.cardLink a {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #6A6B6B;
}

.cardImage {
    height: 212px;
    width: 100%;
}

.cardImageExpanded img,
.cardImage img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.cardImageExpanded {
    min-width: 314px;
    height: 250px;
    max-width: 33%;
    width: 90%;
}

.cardsExpanded {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    width: 100%;
}

.cardExpanded {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    transition: all .3s;
    cursor: pointer;
}

.cardExpandedHeader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
}

.cardExpandedText {
    transition: all .3s;
    margin-left: 24px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 48px;

    overflow: hidden;
    max-width: 0px;
}

.cardExpandedTextInner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 0px;
    gap: 48px;
    min-width: 314px;
}

.cardExpandedText.active {
    max-width: 314px;
}

.cardText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.cardArrow {
    display: none;
}

.promoInner {
    margin-top: 60px;
    padding-right: 72px;
}

.promoRow {
    display: flex;
    grid-gap: 24px;
    width: 100%;
    overflow: hidden;
}

.promoRow + .promoRow {
    margin-top: 35px;
}

.cardExpandedText:nth-child(4):not(.active) {
    margin-left: -24px;
}

@media (min-width: 1700px) {
    .cardExpandedText {
        min-width: 314px;
    }

    .cardExpandedText:nth-child(4):not(.active) {
        margin-left: 24px;
    }

    .cardImage {
        height: 300px;
    }

    .cardImageExpanded {
        width: 100%;
        height: 300px;
    }
}

@media (max-width: 768px) {
    .cardImageExpanded {
        max-width: 100%;
    }

    .card {
        flex-direction: column-reverse;
    }

    .cardImage {
        height: 248px;
    }

    .cardHeader {
        display: flex;
        justify-content: center;
    }

    .cardsExpanded {
        display: grid;
        grid-auto-flow: row dense;
        transition: all .3s;
        grid-template-rows: 1fr 1fr;
        gap: 0 20px;
        grid-template-areas:
        "first second"
        "text text";
    }

    .cardsExpanded.first {
        grid-template-columns: 1.2fr 0.8fr;
    }

    .cardsExpanded.second {
        grid-template-columns: 0.8fr 1.2fr;
    }

    .cardImageExpanded {
        min-width: initial;
        grid-area: first;
        width: initial;
    }

    .cardExpandedText {
        margin-top: 28px;
        grid-area: text;
        max-width: 100%;
        gap: 18px;
        display: grid;
        grid-template-columns: auto 1fr;
        grid-template-rows: auto 1fr;
        grid-template-areas:
        "head head"
        "inner arrow";
    }

    .cardTitle {
        grid-area: head;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 122.8%;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #414141;
        display: flex;
        gap: 12px;
        align-items: center;
    }

    .cardTitle span img {
        height: 32px;
    }

    .cardLink {
        display: none;
    }

    .cardText {
        grid-area: inner;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

    .cardArrow {
        grid-area: arrow;
        display: block;
        margin-top: 30px;
    }

    .promoRow:nth-child(2) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .promoRow:nth-child(2) div:nth-child(1) {
        grid-column: 2 span !important;
    }

    .promoRow + .promoRow {
        margin-top: 52px;
    }

    .promoInner {
        max-width: initial;
        padding: 0 32px;
        margin-top: 52px;
    }
}