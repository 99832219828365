.wrapper {
}

.promo {
    margin-top: 20px;
}

.actions {
    margin-top: 125px;
}

.hits {
    margin-top: 53px;
}

.sectionTitle {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #414141;
    position: relative;
}

.sectionTitle:after {
    position: absolute;
    content: '';
    background: #A4A6A6;
    width: 100%;
    bottom: -14px;
    height: 1px;
    right: 0;
}

.hitsInner {
    padding-right: 72px;
    margin-top: 45px;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 24px;
}

@media (min-width: 1800px) {
    .hitsInner {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 768px) {
    .sectionTitle {
        display: flex;
        justify-content: center;
        text-align: center;
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .sectionTitle:after {
        position: absolute;
        content: '';
        background: #A4A6A6;
        width: 100%;
        top: 50%;
        bottom: initial;
        height: 1px;
        right: 0;
    }

    .sectionTitleText {
        background: #f9f9f9;
        padding: 0;
        margin: 0 32px;
        position: relative;
        z-index: 2;
    }

    .hitsInner {
        padding: 0 32px;
        margin-top: 52px;

        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 24px;
    }

    .hits {
        margin-top: 120px;
    }
}