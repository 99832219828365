.container {
    display: grid;
    grid-template-columns: auto 720px;
    padding: 72px 0;
    max-width: 1440px;
    margin: 60px auto 0;
}

.imagesSection {
    position: relative;
}

.image1 {
    width: 259px;
    height: 333px;
    position: absolute;
    top: 1px;
    left: 72px;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.image2 {
    width: 290px;
    height: 366px;
    position: absolute;
    top: 57px;
    left: 355px;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.image3 {
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
    width: 228px;
    height: 200px;
    position: absolute;
    top: 362px;
    left: 93px;
}

.image1 img, .image2 img, .image3 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.textSection {
    padding: 15px 0;
}

.title {

}

.head {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #333333;
    position: relative;
    margin: 0;
}

.preHead {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
    margin-bottom: 24px;
}

.head:after {
    content: '';
    width: calc(100% - 200px + ((98vw - 1440px)/2));
    height: 1px;
    top: 50%;
    position: absolute;
    background: #A4A6A6;
    left: 200px;
}

.text {
    margin-top: 68px;
    width: 450px;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.button {
    width: 305px;
    margin-top: 63px;
}

.leadTitle {
    font-weight: 400;
    font-size: 28px;
    line-height: 40px;
    letter-spacing: 0.135em;
    color: #C2272F;
}

.leadSubTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    letter-spacing: 0.115em;

    /* red */
    color: #C2272F;
}

.inner {
    width: 567px;
    padding: 53px 0;
}

.lead {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}


@media (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: column-reverse;
        padding: 0;
        margin-top: 120px;
    }

    .title {
        padding: 0 32px;
        background: #f9f9f9;
        z-index: 2;
    }

    .imagesSection {
        margin-top: 40px;
        padding: 0 32px;
        display: flex;
        gap: 15px;
    }

    .image1, .image2 {
        position: initial;
    }

    .image1 {
        height: 365px;
        margin-top: 48px;
    }

    .image2 {
        height: 328px;
    }

    .inner {
        margin-top: 35px;
        padding: 0 32px;
        width: initial;
    }

    .text {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        width: initial;
    }
    .button {
        width: initial;
    }


}
