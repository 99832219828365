.modalOverlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding: 80px 0;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    background: rgba(0, 0, 0, 0.2);
    top: 0;

    overflow: scroll;
    box-sizing: border-box;
    z-index: 10;

    transition: all .4s;
    opacity: 0;
}

.productModalOverlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding: 80px 0;
    right: 0;
    top: 0;

    overflow: scroll;
    box-sizing: border-box;
    z-index: 10;

    transition: all .4s;
    opacity: 0;
}

.modalOpen {
    opacity: 1;
}

.clearfix {
    height: 00px;
    width: 100px;
    bottom: 0;
}


@media (max-width: 768px) {
    .clearfix {
    }
}
