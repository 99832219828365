.main img {
    max-width: 100%;
    height: auto;
}

.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    max-width: 1440px;
    margin: 52px auto 120px auto;
    padding: 0 72px;
}

.pageTitleAlt {
    display: block;
    color: #C2272F;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    text-transform: uppercase;
}

.pageTitle {
    color: #414141;
    font-size: 40px;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: 3.4px;
    text-transform: uppercase;
    margin: 0;
}

.pageSectionLine {
    position: relative;
}

.pageSectionLine::before {
    content: "";
    position: absolute;
    bottom: 0;
    right: 0;
    display: block;
    width: calc(100% - 110px);
    height: 1px;
    background-color: rgba(164, 166, 166, 0.40);
}

.pageListStyleLine {
    padding: 0;
    margin: 0;
    list-style: none;
}

.pageListStyleLineItem {
    position: relative;
    padding-left: 35px;
    font-size: 18px;
    line-height: 20px;
    color: #414141;
    margin-bottom: 10px;
}

.pageListStyleLineItem:last-child {
    margin-bottom: 0;
}

.pageListStyleLineItem::after {
    content: "";
    position: absolute;
    top: 10px;
    left: 12px;
    width: 13px;
    height: 2px;
    background-color: #C2272F;
}

/* intro */

.intro {
    padding-top: 48px;
    padding-bottom: 130px;
}

.introHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 60px;
}

.introHeader .pageTitleAlt {
    margin-right: 25px;
}

.introContent {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
}

.introImgContainer {
    grid-row: -1/1;
}

.introTextContentTop {
    color: #414141;
    padding-left: 100px;
    margin-bottom: 156px;
}

.introTextContentBottomWrapper {
    padding-left: 100px;
}

.introTextTopUppercase {
    margin-top: 0;
    margin-bottom: 15px;
    padding-right: 160px;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 2.34px;
    text-transform: uppercase;
}

.introText {
    margin: 0;
    font-size: 18px;
    line-height: 25px;
    padding-right: 119px;
}

.introTextContentBottom {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.introTextContentBottom span+span {
    margin-bottom: 8px;
}

.introTextRed {
    color: #C2272F;
}

.introTextUppercase {
    font-size: 26px;
    line-height: 32px;
    letter-spacing: 4.2px;
    font-weight: 500;
    text-transform: uppercase;
}


/* / intro */

/* promotion */
.promotion {
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
    padding-bottom: 130px;
    padding-left: 110px;
}

.promotionHeader {
    margin-bottom: 30px;
}

.promotionHeader .pageTitleAlt {
    margin-bottom: 25px;
}

.promotionText {
    max-width: 426px;
    margin-top: 0;
    margin-bottom: 20px;
    color: #414141;
    font-size: 18px;
    line-height: 25px;
}
.promotionTextColor {
    margin: 0;
    color: #C2272F;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 2.3px;
    text-transform: uppercase;
}

.promotionLogotypeContent {
    display: flex;
    flex-direction: column;
    padding-right: 110px;
    gap: 33px;
}


/* / promotion */

/* lottery */

.lottery {
    display: flex;
    justify-content: space-between;
    padding-top: 120px;
    padding-bottom: 130px;
    padding-left: 110px;
}

.lotteryHeader {
    display: flex;
    margin-bottom: 30px;
}

.lotteryHeader .pageTitleAlt {
    margin-right: 25px;
}

.lotteryTextColor {
    margin-top: 0;
    margin-bottom: 60px;
    color: #C2272F;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 2.3px;
    text-transform: uppercase;
}

.lotteryListPrizes {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 60px;
}

.lotteryListPrizesItem {
    color: #414141;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    margin-bottom: 21px;
}

.lotteryListPrizesItem:last-child {
    margin-bottom: 0;
}

.lotteryListPrizesItemColor {
    color: #C2272F;
    font-size: 30px;
}

.lotteryListPrizesItem span {
    text-transform: uppercase;
}

.lotteryList {
    margin-top: 10px;
    margin-bottom: 16px;
}

.lotteryText {
    margin: 0;
    color: #414141;
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
}

.lotteryText+.lotteryText {
    margin-top: 20px;
}

.lotteryLogoContainer {
    margin-left: 10px;
}

.lotteryLogoContent {
    max-width: 530px;
    margin-bottom: 25px;
}

.lotteryLogoText {
    margin: 0;
    margin-bottom: 10px;
    color: #999;
    font-size: 15px;
    line-height: 20px;
}

.lotteryLogoText:last-child {
    margin-bottom: 0;
}

.lotteryLogoText a {
    color: #999;
    text-decoration-color: #999;
    text-underline-offset: 3px;
}

.lotteryLogo {
    width: 100%;
}

.lotteryLogoWrapper {
    min-width: 150px;
    margin-bottom: 10px;
}

.lotteryOtherLogoContent {
    max-width: 430px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 50px;
    row-gap: 10px;
    justify-content: center;
}

.lotteryOtherLogoContent a {
    width: 100%;
    max-width: 100px;
}

.lotteryLogoIdolCut {
    align-self: end;
 }

.lotteryLogoHah {
    align-self: end;
 }

/* / lottery */

/* stickers  */
.stickers {
    padding-top: 150px;
    padding-bottom: 60px;
}

.stickersContainer {
    max-width: 915px;
    margin-left: auto;
    margin-right: auto;
}

.stickersSteps {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 115px;
    display: flex;
    align-items: center;
}

.stickersStepsItem {
    position: relative;
    flex-grow: 1;
}

.stickersStepsItem::after {
    content: "";
    position: absolute;
    top: calc(50% - 19px);
    right: -15px;
    width: 30px;
    height: 38px;
    background-image: url("../../static/images/kuksumania/stickers-arrow.svg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
}

.stickersStepsItem:last-child {
    margin-right: 0;
}

.stickersStepsItem:last-child::after {
    display: none;
}

.stickersStepsItemTextColor {
    display: block;
    text-align: center;
    color: #C20909;
    font-size: 24px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.stickersStepsItemText {
    display: block;
    text-align: center;
    color: #1E1E1E;
    font-size: 20px;
    font-weight: 500;
}

.stickersStepsItemContent {
    min-width: 190px;
    margin: 0;
}

.stickersStepsItemContent {
    margin-bottom: 30px;
}

.stickersStepsItemContent:last-child {
    margin-bottom: 0;
}

.stickersPrizes {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
}

.stickersPrizesItem {
    margin: 0;
    min-height: 186px;
    flex-grow: 1;
}

/* .stickersPrizesItem::after {
    content: "";
    display: block;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    transform: translateX(7px);
}

.stickersPrizesItemThree::after {
    width: 177px;
    height: 61px;
    background-image: url("../../static/images/kuksumania/stickers-three.png");
}

.stickersPrizesItemSix::after {
    width: 178px;
    height: 111px;
    background-image: url("../../static/images/kuksumania/stickers-six.png");
} */

.stickersPrizesItemText {
    display: block;
    color: #1E1E1E;
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
}

.stickersPrizesItemTextColor {
    display: block;
    color: #C20909;
    font-size: 26px;
    font-weight: 500;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 15px;
}

.stickersTextBottom {
    margin: 0;
    color: #B3AFAF;
    font-size: 15px;
    text-align: center;
}

.stickersPrizesItem .stickersPrizesImg {
    max-width: 100px;
}

.stickersPrizesImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

/* / stickers  */

/* steps */
.steps {
    padding-top: 60px;
    padding-bottom: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stepsCard {
    width: 330px;
    min-height: 370px;
    text-decoration: none;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.20);
}

.stepsCardTextContent {
    padding: 24px 16px;
}

.stepsCardTitle {
    color: #414141;
    font-size: 16px;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 35px;
    position: relative;
}

.stepsCardTitle::before {
    content: "";
    display: block;
    position: absolute;
    width: 24px;
    height: 24px;
    right: 0;
    top: -1px;
    background-image: url("../../static/icons/catalog/hit-card.svg");
    background-repeat: no-repeat;
}

.stepsCardText {
    margin-top: 0;
    margin-bottom: 24px;
    color: #6A6B6B;
    font-size: 14px;
    font-weight: 400;
}

.stepsCardBtn {
    display: block;
    box-sizing: border-box;
    padding: 11px 8px;
    width: 100%;
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 1;
    text-transform: uppercase;
    background-color: #C2272F;
    text-align: center;
}

.stepsTextContent {
    width: 500px;
    margin-left: 80px;
}

.stepsList {
    padding: 0;
    margin: 0;
    list-style: none;
    counter-reset: stepsList;
    margin-bottom: 32px;
}

.stepsListItem {
    color: #333;
    font-size: 18px;
    line-height: 30px;
    letter-spacing: 2.34px;
    text-transform: uppercase;
    padding-left: 32px;
    margin-bottom: 35px;
    position: relative;
}

.stepsListItem:last-child {
    margin-bottom: 0;
}

.stepsListItem::before {
    position: absolute;
    left: 0;
    counter-increment: stepsList;
    content: counter(stepsList) "/";
    font-size: 26px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 2.34px;
    text-transform: uppercase;
    color: #C2272F;
}

.stepsListItem a {
    text-decoration-color: #C9C9C9;
    text-underline-offset: 5px;
}

.stepsTextBottom {
    margin: 0;
    color: #414141;
    font-size: 26px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 4.2px;
    text-transform: uppercase;
}

.stepsTextColor {
    color: #C2272F;
}

/* / steps */

/* festival */
.festival {
    padding-top: 120px;
    padding-bottom: 130px;
}

.festivalHeader {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 83px;
}

.festivalHeader .pageTitleAlt {
    margin-bottom: 25px;
}

.festivalContent {
    display: flex;
}

.festivalTextContent {
    width: 500px;
    margin-left: 90px;
}

.festivalText {
    color: #414141;
    margin: 0;
    margin-bottom: 4px;
    font-size: 24px;
    line-height: 40px;
}

.festivalText b {
    font-weight: 500;
}

.festivalTextColor {
    margin-top: 50px;
    margin-bottom: 15px;
    color: #C2272F;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 2.3px;
    text-transform: uppercase;
}

.festivalListTitle {
    margin: 0;
    margin-bottom: 14px;
    color: #414141;
    font-size: 18px;
    line-height: 20px;
}

.festivalList {
    margin-bottom: 50px;
}

.festivalList .pageListStyleLineItem {
    margin-bottom: 14px;
}

.festivalList .pageListStyleLineItem:last-child {
    margin-bottom: 0;
}

.festivalTextBottom {
    color: #414141;
    margin: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
}

.festivalTextBottomColor {
    color: #C2272F;
    text-transform: uppercase;
}

.festivalTextContentLogo {
    display: none;
}

/* / festival */

/*  battle */
.battle {
    padding-top: 120px;
    padding-bottom: 130px;
    padding-left: 110px;
    display: flex;
    justify-content: space-between;
}

.battleHeader .pageTitleAlt {
    margin-bottom: 25px;
}

.battleHeader {
    padding-top: 100px;
    margin-bottom: 30px;
}

.battleContent {
    margin-right: 10px;
}

.battleTextColor {
    margin: 0;
    margin-bottom: 60px;
    color: #C2272F;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 2.34px;
    text-transform: uppercase;
}

.battlePrizes {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 50px;
}

.battlePrizesItem {
    color: #414141;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    margin-bottom: 21px;
}

.battlePrizesItem:last-child {
    margin-bottom: 0;
}

.battlePrizesItemColor {
    color: #C2272F;
    font-size: 30px;
}

.battleList {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 14px;
}

.battleListItem {
    color: #414141;
    font-size: 18px;
    line-height: 25px;
}

.battleTextBottom {
    margin: 0;
    color: #999;
    font-size: 15px;
    line-height: 20px;
}

.battleImgContainer {
    flex-shrink: 0;
}

/* /  battle */


/* program */
.program {
    padding-top: 120px;
    padding-bottom: 110px;
    padding-left: 110px;
}

.programHeader .pageTitleAlt {
    margin-bottom: 25px;
}

.programList {
    color: #414141;
    font-size: 18px;
    line-height: 25px;
    padding-top: 40px;
    padding-right: 30px;
    order: -3;
}

.programListItem {
    display: flex;
    align-items: center;
}

.programListItemLine {
    padding-bottom: 50px;
    margin-bottom: 30px;
    border-bottom: 1.5px solid #C2272F;
}

.programListItemTime {
    flex-shrink: 0;
    color: #C2272F;
    font-size: 20px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 2.34px;
    text-transform: uppercase;
    padding-right: 30px;
}

.programListStyleTitle {
    color: #C2272F;
    font-size: 20px;
    font-weight: 500;
    line-height: 20px;
}

.programListItemTitleTime p {
    margin-bottom: 10px;
}

.programListStyle .pageListStyleLineItem {
    line-height: inherit;
    margin-bottom: 12px;
}

.programListStyle .pageListStyleLineItem:last-child {
    margin-bottom: 0;
}

.programListItemTextContentLine {
    padding-left: 10px;
    border-left: 1.5px solid #C2272F;
}

.programListItemTextContentLine p {
    margin: 0;
    margin-bottom: 20px;
}

.programListItemTextContentLine p:last-child {
    margin-bottom: 0;
}

.programListItemTextContentEmpty {
    padding-left: 165px;
}

.programContent {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto auto;
}

.programImgContainer1 {
    order: -2;
}

.programImgContainer2 {
    margin-top: -230px;
}

.programImgContainer3 {
    order: -1;
    text-align: right;
    padding-right: 60px;
    margin-top: 20px;
}

.programImgContainer4 {
    grid-column: 2;
    padding-right: 20px;
    margin-top: -110px;
}

.programImgContainer4 img {
    width: 100%;
}
/* / program */

/* footer */
.footer {
    background-color: #414141;
}

.footerContainer {
    padding: 64px 72px;
    display: flex;
    justify-content: space-between;
}

.footerSocialTitle {
    display: block;
    color: #F9F9F9;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 3.2px;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 22px;
    text-decoration: none;
}

.footerSocialList {
    display: flex;
    gap: 40px;
    list-style: none;
    padding: 0;
    margin: 0;
}

.footerSocialLink {
    color: #F9F9F9;
    font-size: 14px;
    line-height: 17px;
    text-decoration: none;
    padding-top: 5.5px;
    padding-bottom: 5.5px;
    padding-left: 44px;
    background-image: url("../../static/icons/vk.svg");
    background-repeat: no-repeat;
    background-position: left center;
}

.footerColumnCopyright {
    text-align: right;
}

.footerPolicyLink {
    display: block;
    color: rgba(249, 249, 249, 0.25);
    font-size: 15px;
    line-height: 20px;
    margin-bottom: 29px;
}

.footerCopyright {
    margin: 0;
    color: rgb(249, 249, 249, 0.5);
    font-size: 15px;
    line-height: 20px;
}

/* / footer */

@media (max-width: 1025px) {
    .container {
        padding: 0 32px;
    }

    .pageSectionLine::before {
        width: 100%;
    }

    /* intro */
    .introContent {
        display: block;
    }

    .introHeader {
        display: block;
        margin-bottom: 50px;
    }

    .introHeader .pageTitleAlt {
        margin-bottom: 25px;
    }

    .introImgContainer {
        text-align: center;
        margin-right: 0;
        margin-bottom: 30px;
    }

    .introTextContentTop {
        padding-left: 0;
        margin-bottom: 40px;
    }

    .introTextTopUppercase {
        padding-right: 0;
    }

    .introText {
        padding-right: 0;
    }

    .introTextContentBottomWrapper {
        padding-left: 0;
        display: flex;
        justify-content: flex-end;
    }
    /* /intro */

    /* promotion */
    .promotion {
        display: block;
        padding-left: 0;
    }

    .promotionTextContent {
        margin-bottom: 60px;
    }

    .promotionText {
        max-width: 100%;
    }

    .promotionLogotypeContent {
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        padding-right: 0;
        margin-bottom: 0;
        gap: 35px;
    }

    .promotionLogo1 {
        width: 186px;
    }

    .promotionLogo2 {
        width: 193px;
    }

    .promotionLogo3 {
        width: 190px;
    }
    /* /promotion */

    /* lottery  */
    .lottery {
        display: block;
        padding-left: 0;
    }

    .lotteryTextContent {
        margin-bottom: 108px;
    }

    .lotteryLogoContainer {
        margin-left: 0;
    }

    .lotteryLogoContent {
        max-width: 720px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    .lotteryLogoText {
        max-width: 100%;
        padding-left: 0;
    }

    .lotteryLogoWrapper {
        margin-bottom: 15px;
    }

    .lotteryOtherLogoContent {
        margin-left: auto;
        margin-right: auto;
    }
    /* /lottery  */

    /* steps */
    .steps {
        flex-direction: column;
        align-items: center;
    }

    .stepsCard {
        order: 1;
    }

    .stepsTextContent {
        width: 100%;
        margin-left: 0;
        margin-bottom: 60px;
    }

    .stepsList {
        padding-left: 10px;
        padding-right: 10px;
    }

    .stepsTextBottom {
        text-align: center;
    }
    /* / steps */

    /* festival */
    .festivalContent {
        display: block;
    }

    .festivalImgContainer {
        text-align: center;
    }

    .festivalImg {
        width: 100%;
    }

    .festivalTextContent {
        padding-top: 50px;
        width: 100%;
        margin-left: 0;
    }
    /* / festival */

    /* battle */
    .battle {
        display: block;
        padding-left: 0;
    }

    .battleContent {
        margin-right: 0;
        margin-bottom: 60px;
    }

    .battleImgContainer {
        text-align: center;
    }
    /* / battle */

    /* program */
    .program {
        padding-left: 0;
    }

    .programContent {
        display: block;
    }

    .programList {
        padding-top: 50px;
        margin-bottom: 60px;
        padding-right: 0;
        display: flex;
        flex-direction: column;
    }

    .programListItem {
        display: block;
        margin-bottom: 30px;
    }

    /* .programListItemOrder {
        order: 1;
        margin-bottom: 0;
    } */

    .programListItemTime {
        display: block;
        padding-right: 0;
        margin-bottom: 10px;
    }

    .programListItemTextContentEmpty {
        padding-left: 0;
    }

    .programListItemTextContent {
        padding-left: 0;
    }

    .programListItemTitleTime {
        margin-bottom: 25px;
    }

    .programListItemTitleTime p {
        margin-bottom: 0;
    }

    .programImgContainer {
        margin-top: 0;
        margin-bottom: 30px;
        padding-right: 0;
        text-align: center;
    }

    .programImgContainer4 img {
        width: auto;
    }
    /* / program */

    /* footer */
    .footerContainer {
        display: block;
        padding: 105px 32px;
    }

    .footerColumnSocial {
        margin-bottom: 62px;
    }

    .footerSocialTitle {
        margin-bottom: 40px;
    }

    .footerSocialList {
        flex-direction: column;
        gap: 24px;
    }

    .footerSocialLink {
        font-size: 20px;
        line-height: 24px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .footerColumnCopyright {
        text-align: left;
    }

    .footerPolicyLink {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 16px;
    }

    .footerCopyright {
        font-size: 20px;
        line-height: 24px;
    }
    /* /footer */
}


@media (max-width: 768px) {
    .container {
        padding: 0 20px;
    }

    .pageTitleAlt {
        font-size: 28px;
        letter-spacing: 3px;
    }

    .pageTitle {
        line-height: 53px;
        letter-spacing: 3px;
    }

    /* intro */
    .introTextUppercase {
        font-size: 35px;
        line-height: 41px;
    }

    .introTextContentBottomWrapper {
        justify-content: flex-start;
    }

    .introImg {
        width: 100%;
    }

    .introImgContainer {
        margin-left: -20px;
        margin-right: -20px;
    }

    .introTextContentBottom {
        width: 100%;
        font-size: 18px;
        line-height: 25px;
    }
    /* /intro */

    /* promotion */
    .promotionLogotypeContent {
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding-right: 0;
        margin-bottom: 0;
    }

    .promotionLogotypeContent img {
        max-width: 350px;
        width: 100%;
    }

    /* /promotion */

    /* lottery  */

    .lotteryHeader {
        display: block;
    }

    .lotteryHeader .pageTitleAlt {
        margin-bottom: 25px;
    }

    .lotteryLogoText {
        font-size: 14px;
        padding-left: 0;
    }

    .lotteryListPrizesItem {
        font-size: 20px;
        margin-bottom: 12px;
    }

    .lotteryListPrizesItemColor {
        font-size: 24px;
    }
    /* /lottery  */

    /* stickers */
    .stickers {
        padding-top: 120px;
        padding-bottom: 40px;
    }

    .stickersContainer {
        display: flex;
        flex-direction: column;
    }

    .stickersSteps {
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 20px;
    }

    .stickersStepsItem {
        padding-left: 45px;
        margin-bottom: 40px;
    }

    .stickersStepsItem:last-child {
        margin-bottom: 0;
    }

    .stickersStepsItem::after {
        left: 0;
        right: auto;
    }

    .stickersStepsItem:last-child::after {
        display: block;
    }

    .stickersStepsItemContent {
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    .stickersStepsItemTextColor {
        font-size: 20px;
        text-align: left;
        margin-bottom: 0;
        margin-right: 10px;
    }

    .stickersStepsItemText {
        font-size: 18px;
        text-align: left;
    }

    .stickersPrizes {
        flex-direction: column;
        order: 1;
        margin-bottom: 0;
    }

    .stickersTextBottom {
        text-align: left;
        margin-bottom: 80px;
    }

    .stickersPrizesItem {
        margin-bottom: 40px;
    }

    .stickersPrizesItem:last-child {
        margin-bottom: 0;
    }

    .stickersPrizesItemTextColor {
        font-size: 24px;
    }

    /* stickers */

    /* steps */
    .steps {
        padding-top: 40px;
    }

    .stepsTextBottom {
        font-size: 24px;
    }

    .stepsTextColor {
        display: block;
    }

    .stepsCard {
        width: 100%;
    }

    .stepsCardImg {
        width: 100%;
    }

    .stepsListItem {
        color: #414141;
        font-weight: 500;
    }
    /* / steps */

    /* festival */
    .festivalContent {
        display: flex;
        flex-direction: column;
    }

    .festivalHeader {
        margin-bottom: 30px;
    }

    .festivalTextContent {
        padding-top: 0;
        margin-bottom: 60px;
    }

    .festivalText {
        font-size: 20px;
    }

    .festivalTextBottom {
        font-size: 26px;
        line-height: 35px;
    }

    .festival {
        padding-bottom: 120px;
    }

    .festivalImgContainer {
        order: 1;
    }

    .festivalTextContent img {
        max-width: 60%;
    }

    .festivalTextContentLogo {
        display: block;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }

    .festivalTextColor {
        margin-top: 15px;
    }
    /* / festival */

    /* battle */
    .battleHeader {
        padding-top: 0;
    }

    .battleImg {
        width: 100%;
    }

    .battlePrizesItem {
        font-size: 20px;
    }

    .battlePrizesItem span {
        font-size: 28px;
    }

    .battlePrizesItemColor {
        font-size: 24px;
    }

    .battleListItem:first-child {
        margin-bottom: 30px;
    }
    /* / battle */

    /* program */
    .programImgContainer {
        margin-bottom: 20px;
    }

    .programImgContainer img {
        width: 100%;
    }
    /* / program */

    /* footer */
    .footerContainer {
        padding: 105px 20px;
    }
    /* / footer */

}
