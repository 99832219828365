.container {
    padding: 72px;

    overflow: hidden;
    max-width: 1440px;
    margin: 110px auto 0;
}

.title {
}

.preHead {
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
}

.head {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #333333;
    margin-top: 20px;
    margin-bottom: 0;
}

.inner {
    margin-top: 67px;
    margin-left: 96px;
    width: 100%;
}

.slider {
    display: flex;
    grid-gap: 24px;
}

.testimonial {
    border: 1px solid #6A6B6B;
    padding: 32px;
    width: 326px;
    box-sizing: border-box;
    margin: 0 11px
}

.testimonialImage {
}

.testimonialDate {
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    margin-top: 8px;
}

.testimonialText {
    font-size: 15px;
    line-height: 20px;
    color: #414141;
    margin-top: 15px;
}

.testimonialName {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #6A6B6B;
}

.icons {
    display: flex;
    margin-top: 35px;
    margin-left: 300px;
}

.iconLeft, .iconRight {
    cursor: pointer;
    margin: 0 10px;
}

.testimonialHead {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 768px) {
    .container {
        margin-top: 204px;
        padding: 0 0 0 32px;
    }

    .inner {
        margin-left: 0;
    }

    .slider {
        /*display: initial;*/
        flex-shrink: 0;
    }

    .testimonialText {
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
        color: #414141;
    }
}
