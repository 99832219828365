.container {
    margin: 52px 0 120px 0;
}

.title {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #414141;
    background: #F9F9F9;
    padding: 0 72px;
    z-index: 2;
}

.titleSub {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #A4A6A6;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 1.05rem;
    left: 0;
    z-index: 1;
}

.inner {
    margin-top: 52px;
    padding: 0 72px;
}

.description {
    display: flex;
    gap: 120px;
    align-items: center;
}

.typo {
    display: flex;
    flex-direction: column;
    gap: 36px;
    max-width: 600px;
}

.head {
    font-style: normal;
    color: #414141;

    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
}

.head span {
    color: #C2272F;
}

.text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
}

.button {
    width: 50%;
}


.input {
    border: none;
    background: rgba(164, 166, 166, 0.1);
    padding: 14px 24px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
    width: 100%;
    font-family: 'TT Norms Pro', serif;
}

.input::placeholder {
    font-family: 'TT Norms Pro', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A4A6A6;
}

.input:focus {
    outline: none;
}

.selector, .selector input {
    cursor: pointer;
    position: relative;
}

.selector:after {
    content: '';
    background: url("../../static/icons/arrow_down.svg");
    background-size: cover;
    width: 25px;
    height: 25px;
    right: 12px;
    position: absolute;
    top: calc(50% - 12px);
}

.request {
    margin-top: 200px;
    display: grid;
    gap: 85px;
    grid-template-columns: auto 600px;
    justify-content: space-between;
}

.rules, .form {
    width: 100%;
}

.form {
    padding-right: 50px;
}

.row {
    width: 100%;
    margin-top: 24px;
    display: flex;
    gap: 24px;
}

.row > div {
    width: 100%;
}

.rules {
    display: flex;
    flex-direction: column;
    gap: 72px;
}

.rule {
    display: flex;
    gap: 24px;
}

.rulesList {
    display: flex;
    flex-direction: column;
    gap: 54px;
}

.rulesHead {
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #414141;
    font-size: 24px;
    line-height: 29px;
}

.rulesFoot {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 133%;
    color: #414141;
}

.ruleText {
    font-style: normal;
    font-weight: 400;
    color: #414141;

    font-size: 20px;
    line-height: 160.3%;
    margin-top: -5px;
}

.heading {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #414141;
}


.proceedText {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 133%;
}

.finalButton > div {
    max-width: 300px;
}


.disabled {
    opacity: .5;
}

@media (max-width: 768px) {
    .inner {
        padding: 0px 32px;
        margin-top: 24px;
    }

    .title:after {
        top: 25px;
    }

    .titleText {
        padding: 0 32px;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
    }

    .description {
        flex-direction: column;
        gap: 56px;
    }

    .description img {
        height: 532px;
        width: 100%;
        object-fit: cover;
    }

    .typo {
        gap: 50px;
    }

    .head {
        font-size: 32px;
        line-height: 38px;
    }

    .text {
        font-size: 24px;
        line-height: 133.8%;
    }

    .request {
        display: flex;
        margin-top: 100px;
        flex-direction: column;
    }

    .button {
        width: 100%;
    }

    .rulesHead {
        font-size: 36px;
        line-height: 43px;
        letter-spacing: 0.13em;
    }

    .ruleText {
        font-weight: 400;
        font-size: 24px;
        line-height: 160.3%;
        margin-top: -8px;
    }

    .button span {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .input, .textarea, .input::placeholder, .textarea::placeholder,
    input, textarea, input::placeholder, textarea::placeholder {
        font-size: 20px;
    }

    .input, .textarea, input, textarea {
        padding: 24px 24px;
    }


    .heading {
        font-size: 20px;
        line-height: 29px;
    }

    .button span {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .nextButton > div:not(.back) > span {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
    }

    .back span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
    }
}