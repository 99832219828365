.container {
    margin: 52px 0 120px 0;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.titleBack {
    background: #f9f9f9;
    z-index: 2;
    padding: 0 72px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
}

.titleBack span {
    height: 20px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    padding: 0 72px 0 82px;
    text-transform: uppercase;
    color: #414141;
    background: #f9f9f9;
    position: relative;
    z-index: 2;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 50%;
    z-index: 1;
}

.inner {
    padding: 0 72px;
    margin-top: 56px;
    display: grid;
    grid-template-columns: 355px auto;
    gap: 85px;
}

.loader {
    padding: 200px 0;
}

.contactsTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttonContacts {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #6A6B6B;
    cursor: pointer;
}

.sectionTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #6A6B6B;
}

.personal {
    display: flex;
    flex-direction: column;
    gap: 72px;
}

.contacts, .addresses {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.form {
    display: flex;
    flex-direction: column;
    gap: 14px;
}

.formRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formRowLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #A4A6A6;
}

.formRowInput {
}

.input {
    border: none;
    background: rgba(164, 166, 166, 0.1);
    padding: 14px 24px;
    box-sizing: border-box;
    color: #414141;
    width: 100%;
    font-family: 'TT Norms Pro', serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.05em;
}

.input::placeholder {
    font-family: 'TT Norms Pro', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A4A6A6;
}

.input:focus {
    outline: none;
}

.inputReadonly {
    background: rgba(253, 253, 253, 0.1);
}

.addressList {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.addressItem {
    display: flex;
    gap: 32px;
    align-items: flex-start;
}

.addressTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.addressDetails {
    display: flex;
    gap: 10px;
    flex-direction: column;
}

.addressIcon {
}

.addressButton {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #6A6B6B;
    cursor: pointer;
}

.addressButton:hover {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #C2272F;
}

.orders {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.exit {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: end;
    cursor: pointer;
}

.exit span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #6A6B6B;
}

.order {
    border: 1px solid #A4A6A6;
    padding: 30px 32px;
    transition: all .3s;
}

.orderInfo{}
.orderInfoList{}

.toggle {
    cursor: pointer;
    transition: all .3s;
}

.toggle.active {
    transform: rotate(180deg);
}

.orderHolder {
    display: grid;
    grid-template-columns: 260px 1fr;
    gap: 60px;
    padding: 20px 0 0 0;
    box-sizing: border-box;
}

.orderInfoHead {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.orderInfoTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #414141;
}

.orderInfoDate {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
}

.orderInfoList {
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin-top: 48px;
}

.listItem {
    display: flex;
    flex-direction: column;
    gap: 8px
}

.listTitle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #A4A6A6;
}

.listText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #414141;
}

.orderProducts {
    width: 100%;
}

.products {
    display: flex;
    flex-direction: column;
    gap: 28px
}

.product {
    align-items: center;
    display: grid;
    grid-template-columns: 78px 220px 30px 57px;
    gap: 18px
}

.productImage {
}

.productImage img {
    object-fit: cover;
    width: 78px;
    height: 58px;
}

.productName {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #414141;
}

.productTitle {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.productSub span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A4A6A6;
}

.productSub span + span {
    margin-left: 18px;
}

.productCount {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #414141;
    justify-self: flex-end;
}

.productPrice {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #A4A6A6;
    justify-self: flex-end;
}

.productTotal {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.productTotalRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.productTotalPrice {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #A4A6A6;
}

.productTotalLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #A4A6A6;
}

.orderTotal {
    margin-top: 20px;
    padding-top: 18px;
    border-top: 1px solid #A4A6A6;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.orderTotalLabel {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    /* or 21px */
    letter-spacing: 0.05em;
    text-transform: uppercase;

    /* grey */
    color: #414141;
}

.orderTotalPrice {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414141;
}

.collapse {
    transition: all .5s;
}

.collapseTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.collapseTitle > div {
    display: flex;
    gap: 60px;
    flex-direction: row;
    white-space: nowrap;
}

.titleExit {
    display: none;
}

@media (max-width: 768px) {
    .titleBack {
        display: none;
    }

    .container {
        margin: 72px 0 120px 0;
    }

    .titleExit {
        background: #f9f9f9;
        z-index: 2;
        padding: 0 72px;
        display: flex;
        align-items: center;
        gap: 8px;
        font-style: normal;
        color: #6A6B6B;
        position: absolute;
        top: -50px;
        left: -50px;

        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: initial;
        text-transform: capitalize;
    }

    .titleExit span {
        height: 20px;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
        justify-content: center;
    }

    .titleText {
        margin: 0 32px;
        padding: 0 32px;
    }

    .title:after {
        position: absolute;
        content: '';
        background: #A4A6A6;
        width: 100%;
        top: 50%;
        bottom: initial;
        height: 0.7px;
        right: 0;
    }

    .inner {
        display: flex;
        flex-direction: column;
        padding: 0 32px;
    }

    .orderHolder {
        display: flex;
        flex-direction: column;
    }

    .sectionTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #A4A6A6;

    }

    .buttonContacts {
        font-size: 18px;
    }

    .formRowLabel {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #6A6B6B;
        width: 150px;
    }

    .formRowInput {
        width: 100%;
    }
    .formRowInput input, .formRowInput input::placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 29px;
    }

    .addressTitle {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 33px;
        color: #414141;
    }

    .addressList {
        gap: 48px;
    }

    .addressIcon img {
        height: 50px;
    }
    .addressButton {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #A4A6A6;
        align-items: center;
    }

    .addressButton:hover {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }

    .orderInfoTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .orderInfoDate {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #A4A6A6;
    }

    .orderInfoList {
        gap: 52px;
    }

    .listTitle {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .listText {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }
    .products {
        gap: 48px;
    }

    .product {
        display: grid;
        flex-direction: row;
        grid-template-columns: 200px auto;
        grid-template-rows: auto auto auto;
        gap: 24px;
        grid-template-areas:
                "image title"
                "image options"
                "image numbers";
    }

    .productImage {
        grid-area: image
    }

    .productTitle {
        grid-area: title
    }

    .productName {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #414141;
    }

    .productSub span {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #A4A6A6;
    }

    .productSub span + span {
        margin: 0;
    }

    .productNumbers {
        grid-area: numbers;
        display: flex;
        justify-content: space-between;
    }

    .productSub {
        display: flex;
        flex-direction: column;
        grid-area: options;
        gap: 8px
    }

    .productImage img {
        width: 200px;
        height: 200px;
    }

    .productPrice {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #414141;
    }

    .productCount {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .productTotalLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .orderTotalLabel {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

    .productTotalPrice {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .orderTotalPrice {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 35px;
        text-transform: uppercase;
        color: #414141;
    }

    .collapseTitle > div {
        display: flex;
        gap: 12px;
        flex-direction: column;
        white-space: nowrap;
    }

    .exit {
        display: none;
    }

}