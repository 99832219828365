.main {
    margin: 15px auto 125px auto;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 108px;
    position: relative;
}

.sidebar {
}

.wrapper {
}

@media (max-width: 768px) {
    .main {
        max-width: initial;
        display: flex;
        flex-direction: column;
        grid-gap: 52px;
    }
}