
.calendar {
    width: 100%;
    padding: 42px 32px;
    gap: 48px;
    display: flex;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.calendarSelector {

}

.calendarTimeLabel {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #414141;
}

.calendarDetail {
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    justify-content: center;
}

.calendarDate {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #414141;
}

.calendarTimeSelector {
    padding: 8px 0;
    gap: 8px;
    border: 0.5px solid #C2272F;
    text-align: center;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    text-transform: uppercase;
    color: #414141;
}

.calendarTime {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 10px
}

.calendarInterval {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.calendarInterval.line {
    align-items: center;
    flex-direction: row;
}

.calendarIntervalLabel {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #414141;
    white-space: nowrap;
}

.calendarIntervalPickup {
    display: flex;
    flex-direction: row;
}

.calendarIntervalValue {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #C2272F;
}

.calendarButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    gap: 16px;
    background: #C2272F;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;

    color: #FFFFFF;
    cursor: pointer;

}

@media (max-width: 768px) {
    .calendar {
        padding: 48px 44px;
        display: flex;
        flex-direction: column;
    }

    .calendarDetail {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr auto auto;
        grid-gap: 0 24px !important;
        grid-template-areas:
    "date selector"
    "interval interval"
    "button button";

        flex-direction: row;
        justify-content: space-between;
        align-items: flex-end;
        gap: 24px
    }

    .calendarDate {
        grid-area: date;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
        text-align: left;
    }

    .calendarSelector {
        grid-area: selector;
        width: 100%;
    }

    .calendarInterval {
        margin-top: 24px;

        display: flex;
        flex-direction: row;
        grid-area: interval;
        flex-wrap: nowrap;
    }

    .calendarIntervalLabel {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }

    .calendarIntervalValue {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .calendarButton {
        margin-top: 24px;

        grid-area: button;
        font-size: 20px;
        padding: 18px;
    }

    .calendarTimeLabel {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-transform: uppercase;
        color: #414141;
    }

    .calendarTimeSelector {
        padding: 12px 0;
        font-size: 20px;
    }

    .calendarDate span:nth-child(1) {
        display: block;
    }
}