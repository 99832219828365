html, body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #F9F9F9;
    font-feature-settings: 'pnum' on, 'lnum' on;

    font-family: 'TT Norms Pro', serif;
    font-style: normal;
}

a {
    color: #414141;
}

input, input::placeholder, textarea, textarea::placeholder {
    font-family: 'TT Norms Pro', serif;
}

body {
    overflow-x: hidden;
    color: #363538;
}

textarea {
    resize: none;
}

.gm-style-iw.gm-style-iw-c {
    border-radius: 0;
}

.gm-style-iw button {
    display: none !important;
}

@media (max-width: 768px) {
    .react-dadata__suggestion {
        font-size: 18px;
    }

    div:not(.productCard) [data-rsbs-backdrop] {
        display: none;
    }

    div.productCard [data-rsbs-backdrop] {
        z-index: 12;
        backdrop-filter:blur(2px);-webkit-backdrop-filter:blur(2px)
    }

    [data-rsbs-header],
    [data-rsbs-scroll] {
        background: #F9F9F9;
    }

    [data-rsbs-overlay] {
        z-index: 24 !important;
        border-top-left-radius: 0px !important;
        border-top-right-radius: 0px !important;
    }


    div:not(.productCard) [data-rsbs-header]:before {
        display: none !important;
    }

    .react-calendar__tile {
        font-size: 16px;
        height: 50px;
        width: 50px;
    }

    .react-calendar__navigation button:disabled {
        font-size: 24px;
    }

    .react-calendar {
        width: initial;
    }

    [data-rsbs-root]:not(.withPicture) [data-rsbs-header] {
        height: 20px;
        width: 100%;
        background: #F9F9F9;
        position: absolute;
        box-shadow: unset;
    }

    [data-rsbs-root].withPicture [data-rsbs-header] {
        height: 40px;
        width: 100%;
        position: absolute;
        background: rgba(249, 249, 249, 0);
        box-shadow: unset;
    }

    [data-rsbs-root].withPicture [data-rsbs-header]:after {
        content: "";
        background: #F9F9F9;
        height: 7px;
        width: 70%;
        position: absolute;
        opacity: 0.5;
        left: 0;
        right: 0;
        margin: 0 auto;
        top: 20px;
        border-radius: 5px;
    }

    .rbs-half [data-rsbs-header] {
        height: 65px;
    }

    [data-rsbs-footer] {
        overflow: visible !important;
    }
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-Bold.eot');
    src: local('TT Norms Pro Bold'), local('TTNormsPro-Bold'),
    url('./static/fonts/TTNormsPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-Bold.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-Bold.woff') format('woff'),
    url('./static/fonts/TTNormsPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-ExtraBoldItalic.eot');
    src: local('TT Norms Pro ExtraBold Italic'), local('TTNormsPro-ExtraBoldItalic'),
    url('./static/fonts/TTNormsPro-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-ExtraBoldItalic.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-ExtraBoldItalic.woff') format('woff'),
    url('./static/fonts/TTNormsPro-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-ExtraLightItalic.eot');
    src: local('TT Norms Pro ExtraLight Italic'), local('TTNormsPro-ExtraLightItalic'),
    url('./static/fonts/TTNormsPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-ExtraLightItalic.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-ExtraLightItalic.woff') format('woff'),
    url('./static/fonts/TTNormsPro-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-Thin.eot');
    src: local('TT Norms Pro Thin'), local('TTNormsPro-Thin'),
    url('./static/fonts/TTNormsPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-Thin.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-Thin.woff') format('woff'),
    url('./static/fonts/TTNormsPro-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-ExtraBold.eot');
    src: local('TT Norms Pro ExtraBold'), local('TTNormsPro-ExtraBold'),
    url('./static/fonts/TTNormsPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-ExtraBold.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-ExtraBold.woff') format('woff'),
    url('./static/fonts/TTNormsPro-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-Regular.eot');
    src: local('TT Norms Pro Regular'), local('TTNormsPro-Regular'),
    url('./static/fonts/TTNormsPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-Regular.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-Regular.woff') format('woff'),
    url('./static/fonts/TTNormsPro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-ThinItalic.eot');
    src: local('TT Norms Pro Thin Italic'), local('TTNormsPro-ThinItalic'),
    url('./static/fonts/TTNormsPro-ThinItalic.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-ThinItalic.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-ThinItalic.woff') format('woff'),
    url('./static/fonts/TTNormsPro-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-Light.eot');
    src: local('TT Norms Pro Light'), local('TTNormsPro-Light'),
    url('./static/fonts/TTNormsPro-Light.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-Light.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-Light.woff') format('woff'),
    url('./static/fonts/TTNormsPro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-MediumItalic.eot');
    src: local('TT Norms Pro Medium Italic'), local('TTNormsPro-MediumItalic'),
    url('./static/fonts/TTNormsPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-MediumItalic.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-MediumItalic.woff') format('woff'),
    url('./static/fonts/TTNormsPro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms Pro ExtraBlack';
    src: url('./static/fonts/TTNormsPro-ExtraBlackItalic.eot');
    src: local('TT Norms Pro ExtraBlack Italic'), local('TTNormsPro-ExtraBlackItalic'),
    url('./static/fonts/TTNormsPro-ExtraBlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-ExtraBlackItalic.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-ExtraBlackItalic.woff') format('woff'),
    url('./static/fonts/TTNormsPro-ExtraBlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-ExtraLight.eot');
    src: local('TT Norms Pro ExtraLight'), local('TTNormsPro-ExtraLight'),
    url('./static/fonts/TTNormsPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-ExtraLight.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-ExtraLight.woff') format('woff'),
    url('./static/fonts/TTNormsPro-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-LightItalic.eot');
    src: local('TT Norms Pro Light Italic'), local('TTNormsPro-LightItalic'),
    url('./static/fonts/TTNormsPro-LightItalic.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-LightItalic.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-LightItalic.woff') format('woff'),
    url('./static/fonts/TTNormsPro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-Medium.eot');
    src: local('TT Norms Pro Medium'), local('TTNormsPro-Medium'),
    url('./static/fonts/TTNormsPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-Medium.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-Medium.woff') format('woff'),
    url('./static/fonts/TTNormsPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro ExtraBlack';
    src: url('./static/fonts/TTNormsPro-ExtraBlack.eot');
    src: local('TT Norms Pro ExtraBlack'), local('TTNormsPro-ExtraBlack'),
    url('./static/fonts/TTNormsPro-ExtraBlack.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-ExtraBlack.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-ExtraBlack.woff') format('woff'),
    url('./static/fonts/TTNormsPro-ExtraBlack.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-BoldItalic.eot');
    src: local('TT Norms Pro Bold Italic'), local('TTNormsPro-BoldItalic'),
    url('./static/fonts/TTNormsPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-BoldItalic.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-BoldItalic.woff') format('woff'),
    url('./static/fonts/TTNormsPro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-BlackItalic.eot');
    src: local('TT Norms Pro Black Italic'), local('TTNormsPro-BlackItalic'),
    url('./static/fonts/TTNormsPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-BlackItalic.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-BlackItalic.woff') format('woff'),
    url('./static/fonts/TTNormsPro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-Black.eot');
    src: local('TT Norms Pro Black'), local('TTNormsPro-Black'),
    url('./static/fonts/TTNormsPro-Black.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-Black.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-Black.woff') format('woff'),
    url('./static/fonts/TTNormsPro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'TT Norms Pro';
    src: url('./static/fonts/TTNormsPro-Italic.eot');
    src: local('TT Norms Pro Italic'), local('TTNormsPro-Italic'),
    url('./static/fonts/TTNormsPro-Italic.eot?#iefix') format('embedded-opentype'),
    url('./static/fonts/TTNormsPro-Italic.woff2') format('woff2'),
    url('./static/fonts/TTNormsPro-Italic.woff') format('woff'),
    url('./static/fonts/TTNormsPro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}
