.container {
    margin: 52px 0 120px 0;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.titleBack {
    background: #f9f9f9;
    z-index: 2;
    padding: 0 72px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    cursor: pointer;
}


.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    padding: 0 72px 0 82px;
    text-transform: uppercase;
    color: #414141;
    background: #f9f9f9;
    position: relative;
    z-index: 2;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 50%;
    z-index: 1;
}

.inner {
    padding: 0 72px;
    margin: 52px auto 0;
    max-width: 1440px;
}

.images {
    display: flex;
    position: relative;
    margin-bottom: 65px;
}

.images img:nth-child(2) {
    position: relative;
    top: 65px
}

.heading {
    display: flex;
    gap: 72px;
    align-items: center;
}

.headingText {
    display: flex;
    flex-direction: column;
    gap: 48px;
}

.headingTextAbout {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.headingList {
    display: flex;
    flex-direction: column;
    gap: 35px;
    border-left: 1px solid #C2272F;
    padding: 0 32px;
}

.headingListItem {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #414141;

}

.vacancies {
    display: flex;
    gap: 52px;
    padding-top: 72px;
    border-top: 1px solid #A4A6A6;
    margin-top: 67px;
}

.form {
    padding-top: 72px;
    border-top: 1px solid #A4A6A6;
    margin-top: 67px;
}
.formInner {
    display: flex;
    gap: 104px;
    align-items: center;
    padding: 0 82px;
}

.formVacancy {
    width: 448px;
    min-width: 448px;
}

.formDesc {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.formHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.085em;
    text-transform: uppercase;
    color: #414141;
}

.formText {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;

    /* grey */
    color: #414141;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 48px;
    width: 100%;
}

.listItem.active {
    opacity: 1;
    background: #F9F9F9;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.listItemIcon {
    transition: all .3s;
    transform: rotate(90deg);
}

.listItem.active .listItemIcon {
    transform: rotate(0deg);
}

.listItem:hover {
    opacity: 1;
}

.listItem {
    cursor: pointer;
    transition: all .3s;
    opacity: 0.5;
    padding: 32px;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.listItemHead {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.listItemTitle {
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-transform: uppercase;
    color: #414141;
}

.vacancy {
    box-sizing: border-box;

    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 48px;
    background: #F9F9F9;
    /* shadow */
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
    padding: 72px 48px;
}

.description {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #414141;
}

.blockTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #414141;
}

.textBlock {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.textBlock li {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #414141;
}

.textBlock ul {
    margin: 0;
    padding: 0 15px;
}

.skillsBlock {

}

.skillsBlock .blockList {
    display: flex;
    flex-direction: row;
    gap: 16px 8px;
    flex-wrap: wrap;
}

.skill {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    display: flex;
    padding: 8px 12px;

    background: #F9F9F9;
    border: 1px solid #A4A6A6;
}

.current {
    width: 100%;
}

@media (max-width: 768px) {
    .titleBack {
        display: none;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .titleText {
        padding: 0;
        margin: 0 32px;
    }

    .title:after {
        position: absolute;
        content: '';
        background: #A4A6A6;
        width: 100%;
        top: 50%;
        bottom: initial;
        height: 0.7px;
        right: 0;
    }

    .inner {
        padding: 0 32px;
    }

    .heading {
        flex-direction: column;
        gap: 60px;
    }

    .images {
        gap: 8px;
    }

    .images img {
        object-fit: cover;
    }

    .images img:nth-child(1) {
        height: 430px;
        width: 200px;
    }

    .images img:nth-child(2) {
        height: 461px;
        width: 299px;
    }

    .headingTextAbout {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

    .headingList {
        padding: 0 24px;
    }

    .headingListItem {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #414141;
    }

    .current {
        display: none;
    }

    .listItem {
        transition: all .3s;
    }

    .vacancy {
        box-shadow: unset;
        padding: 0;
    }

    .collapse {
        transition: all .3s;
    }

    .listItemBody,
    .description {
        font-size: 24px;
        line-height: 29px;
    }

    .textBlock li {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

    .skill {
        font-size: 18px;
    }

    .formInner {
        flex-direction: column-reverse;
        padding: 0;
        gap: 48px;
    }

    .formVacancy {
        width: 100%;
        max-width: 100%;
    }
}