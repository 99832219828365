.modal {
    width: 1296px;
    gap: 52px;
    padding: 72px 52px;

    background: #F9F9F9;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);

    position: absolute;
    margin: 0 auto;
    z-index: 11;
    box-sizing: border-box;
    left: 0;
    right: 0;
    top: 50px;

    transition: all .4s ease;

    opacity: 0;
    animation: trans-in .4s backwards;
}

.cross {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
}

.cart {
    display: flex;
    justify-content: space-between;
}

@-webkit-keyframes trans-in {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateY(0px);
    }
}

@keyframes trans-in {
    0% {
        transform: translateY(100px);
    }
    100% {
        transform: translateY(0px);
    }
}

.modalOpen {
    opacity: 1;
    animation: trans-in .4s forwards;
}

.modal:after {
    position: absolute;
    content: 'clearfix';
    bottom: -50px;
    opacity: 0;
}

.close {
    position: absolute;
    cursor: pointer;
    right: 30px;
    top: 30px;
}

.colProducts {
    width: 50%;
}

.colContacts {
    width: 45%;
    height: 100%;
    top: 0;
    position: sticky;
}

.contacts {
}

.title {
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #414141;
}

.error {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #C2272F;
}

.products {
    margin-top: 72px;
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.product {
    display: grid;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 24px;
    grid-template-columns: 105px 180px 115px auto auto;
}

.persons {
    margin-top: 32px;
    display: grid;
    grid-template-columns: 154px 115px;
    grid-gap: 180px;
}

.productImage {
    width: 104px;
    height: 85px;
}

.productImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.productName {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.productTitle {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #414141;
}

.productSubtitle {
    display: flex;
    gap: 20px;
}

.productWeight {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A4A6A6;
    white-space: nowrap;
}

.productOption {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #A4A6A6;
    width: 100%;
}

.productSum {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #414141;
    width: 60px;
}

.counter {
    display: flex;
    border: 1px solid #A4A6A6;
    padding: 8px;
    box-sizing: border-box;
    justify-content: space-between;
}

.productClear, .counter img {
    cursor: pointer;
}

.counterCount {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #A4A6A6;
}

.personsIcon {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px;
    gap: 15px;
}

.personsText {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #414141;
}

.delivery {
    margin-top: 72px;
}


.heading, .heading span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;

    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    /* identical to box height */
    text-transform: uppercase;

    /* grey */
    color: #414141;
}

span.changeDeliveryType {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    text-transform: uppercase;
    color: #A4A6A6;
    cursor: pointer;
}

.deliveryText {
    margin-top: 12px;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #414141;
}

.line {
    margin-top: 32px;
    background: #A4A6A6;
    height: 1px;
    width: 100%;
}

.textarea,
.input {
    border: none;
    background: rgba(164, 166, 166, 0.1);
    padding: 14px 24px;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
    width: 100%;
    font-family: 'TT Norms Pro', serif;
}

.textarea::placeholder,
.input::placeholder {
    font-family: 'TT Norms Pro', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A4A6A6;
}

.textarea:focus,
.input:focus {
    outline: none;
}

.textarea {
    resize: none;
    height: 120px;
}

.promo {
    margin-top: 35px;
    display: flex;
    gap: 32px;
    align-items: center;
}

.promo .input {
    width: 50%;
    min-width: 300px;
}

.promoError,
.promoApply {
    max-width: 250px;
}


.promoButton {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-decoration-line: underline;
    color: #6A6B6B;
    cursor: pointer;
}

.sum {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.sumLabel {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #414141;
}

.sumPrice {
    display: flex;
    grid-gap: 24px;
    align-items: center;
}

.sumDiscount {
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    text-decoration-line: line-through;
    text-transform: uppercase;
    color: #A4A6A6;
}

.sumActual {
    font-weight: 500;
    font-size: 30px;
    line-height: 143.8%;
    text-transform: uppercase;
    color: #414141;
}

.row {
    width: 100%;
    margin-top: 24px;
    display: flex;
    gap: 24px;
}

.addressInput {
    width: 100%;
    position: relative;
}

.addressChange {
    position: absolute;
    right: 20px;
    top: calc(50% - 8px);
    font-size: 12px;
    cursor: pointer;
    color: #6A6B6B;
    text-decoration: underline;
}

.changeWrapper {
    display: flex;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
}

.rowHalf {
    width: 50%;
    display: flex;
    gap: 24px;
}

.payment, .address {
    margin-top: 50px;
}

.agreement {
    width: 80%;
}

.button {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 16px 8px;
    gap: 16px;
    width: 70%;
    background: #C2272F;
    opacity: 0.5;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;
}


.button.active {
    opacity: 1;
}

.deliveryTime {
    display: flex;
    justify-content: space-between;
    height: 32px;
}

.selectTime span {
    white-space: nowrap;
}

.deliveryTimeLabel {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 14px
}

.deliveryTimeLabel span {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #414141;
}

.paymentType {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 4px;
    background: #F9F9F9;
    border: 1px solid #A4A6A6;
    font-weight: 500;
    font-size: 14px;
    line-height: 133%;
    color: #A4A6A6;
    box-sizing: border-box;
    cursor: pointer;
}

.paymentType.active,
.paymentType:hover {
    background: #414141;
    border: 1px solid #414141;
    color: #F9F9F9;
}

.unavailable .productImage {
    opacity: 0.3;
}

.unavailableTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #C2272F;
}

.cartEmpty {
    display: flex;
    flex-direction: column;
    gap: 36px;
    margin-top: 36px;
}

.cartEmptyText {
    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #414141;
}

.cartEmptyButton {
    padding: 16px 8px;
    gap: 16px;
    background: #C2272F;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 50%;
    cursor: pointer;
}

.bsClearfix {
    background: #A4A6A6;
    opacity: 0.5;
    border-radius: 30px;
    width: 190px;
    height: 5px;
    left: 0;
    right: 0;
    top: 24px;
    margin: 2px auto;
}

.pickupDiscount {
    color: #C2272F !important;
}

.promoError,
.promoApply {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    text-transform: lowercase;
}

.promoError {
    color: #C2272F;
}

.promoApply {
    color: #62C227;
}

.promoInvalid, .promoValid {
    position: relative;
}

.promoValid:after {
    width: 24px;
    height: 24px;
    content: "";
    position: absolute;
    left: 260px;
    top: 12px;
    background-image: url("../../static/icons/cart/greenmark.svg");
}

.promoInvalid:after {
    width: 24px;
    height: 24px;
    content: "";
    position: absolute;
    left: 260px;
    top: 12px;
    background-image: url("../../static/icons/cart/redcross.svg");
}

.paymentAlert {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
    color: #C2272F;
}

.sumTextContainer {
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.sumText {
    margin: 0;
    font-size: 14px;
    line-height: 17px;
    color: #6a6b6b;
}

.timeText {
    font-size: 14px;
    line-height: 17px;
    color: #C2272F;
}

@media (max-width: 768px) {
    .cart {
        display: flex;
        flex-direction: column;
        padding: 72px 32px 32px;
    }

    .products {
        margin-top: 48px;
    }

    .colProducts,
    .colContacts {
        width: 100%;
    }

    .product {
        display: grid;
        flex-direction: row;
        grid-template-columns: 200px auto;
        grid-template-rows: auto auto auto;
        gap: 24px;
        grid-template-areas:
                "image title"
                "image options"
                "image numbers";
    }

    .productImage {
        grid-area: image
    }

    .productTitle {
        grid-area: title;
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
    }

    .productName {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        color: #414141;
    }

    .productImage {
        width: 200px;
        height: 200px;
    }

    .productSubtitle {
        display: flex;
        flex-direction: column;
        grid-area: options;
        gap: 8px;
    }

    .productName {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .productSubtitle div {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #A4A6A6;
    }

    .productSubtitle div + div {
        margin: 0;
    }

    .productNumbers {
        grid-area: numbers;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .productSum {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #414141;
        width: 55px;
        white-space: nowrap;
    }

    .productClear img {
        width: 48px;
        height: 48px;
    }

    .counter {
        padding: 12px;
        max-height: 45px;
        gap: 24px;
    }

    .persons {
        margin-top: 70px;
        display: flex;
        justify-content: space-between;
    }

    .personsText {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        color: #414141;
    }

    .personsIcon > img {
        width: 42px;
    }

    .persons {
        align-items: center;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 38px;
        line-height: 46px;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;

        display: flex;
        gap: 24px;
        align-items: flex-start;
    }

    .heading, .heading span {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        color: #414141;
    }

    span.changeDeliveryType {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 17px;
        text-decoration-line: underline;
        text-transform: uppercase;
        color: #A4A6A6;
        cursor: pointer;
    }

    .sumLabel {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #414141;
    }

    .sumActual {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .sumDiscount {
        font-style: normal;
        font-weight: 400;
        font-size: 30px;
        line-height: 36px;
        text-decoration-line: line-through;
        text-transform: uppercase;
        color: #A4A6A6;
    }

    .textarea, .textarea::placeholder,
    .input, .input::placeholder {
        font-size: 20px;
    }

    .address .input {
        padding: 14px 18px;
    }
    .address .input::placeholder {
        font-size: 18px;
    }

    .contacts .row {
        flex-direction: column;
    }

    .heading, .heading > span:nth-child(1) {
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
    }

    .payment, .address {
        margin-top: 72px;
    }

    .promoButton {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #6A6B6B;
    }


    .deliveryTimeLabel span {
        white-space: nowrap;
        font-size: 17px;
        line-height: 24px;
    }

    .promoError,
    .promoApply {
        font-size: 20px;
    }

    .error {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        text-transform: uppercase;
        color: #C2272F;

        margin-top: 52px;
    }

    .error img {
        width: 32px;
    }

    .colContacts {
        border-top: 1px solid #A4A6A6;
        margin-top: 82px;
        padding-top: 45px;
    }

    .colContacts .title {
        width: 70%;
    }

    .contacts {
        margin-top: 62px;
    }

    .paymentRow {
        gap: 18px
    }

    .paymentType {
        padding: 18px;
        white-space: nowrap;
        font-weight: 500;
        font-size: 18px;
        line-height: 133%;
    }

    .agreement {
        width: 100%;
        margin-top: 32px;
    }

    .button {
        width: 100%;
        padding: 24px 0px;
        margin-top: 32px;

        font-weight: 700;
        font-size: 22px;
        line-height: 22px;
    }

    .addressChange {
        font-size: 16px;
    }

    .sumTextContainer {
        margin-top: 10px;
    }

    .sumText {
        font-size: 20px;
        line-height: 24px;
    }

    .timeText {
        font-size: 20px;
        line-height: 24px;
    }
}
