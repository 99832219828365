.card {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 403px;
    background: #F9F9F9;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.cardBig {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 453px;
    background: #F9F9F9;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.cardTitle {
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #C2272F;
}

.cardText {
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.cardButton {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-decoration-line: underline;
    color: #414141;

    cursor: pointer;
}

.cardInner {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 32px 24px;
    gap: 24px;
    width: 100%;
    box-sizing: border-box;
}

.cardBig .cardInner {
    padding: 32px 48px;
}

.cardImage {
    width: 100%;
    height: 212px;
}

.cardBig .cardImage {
    height: 262px;
}

.cardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.actionsInner {
    margin-top: 45px;
    width: 100%;
    grid-gap: 24px;
    padding-right: 72px;
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.actionsButton {
    justify-content: flex-end;
    display: flex;
    margin-top: 30px;
    padding-right: 72px;
}

.actionsButton a {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0;
    gap: 12px;
    justify-content: center;
    text-decoration: none;
    color: #000;
}

.actionsButtonText {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #000000;
}

@media (max-width: 768px) {
    .card {
        height: initial;
    }

    .cardTitle {
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-transform: uppercase;
        color: #C2272F;
    }

    .cardText {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #414141;
    }

    .cardInner {
        gap: 16px;
        display: grid;
    }

    .card:not(.cardBig) .cardInner {
        grid-template-columns: 1fr auto;
        grid-template-areas:
                "title arrow"
    }

    .cardBig .cardInner {
        grid-template-columns: 1fr auto;
        grid-template-rows: auto auto;
        grid-template-areas:
                "title arrow"
                "txt arrow";
    }

    .cardText {
        grid-area: txt;
    }

    .card:not(.cardBig) .cardText {
        display: none;
    }

    .cardButton {
        grid-area: arrow;
        align-self: center;
    }

    .cardTitle {
        grid-area: title;
    }

    .actionsInner {
        padding: 0 32px;
        margin-top: 52px;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }

    .actionsInner .card:nth-child(1) {
        grid-column: 2 span !important;
    }

    .actionsButton {
        margin-top: 52px;
        padding: 0 32px;
    }

    .actionsButton div{
        padding: 23px 20px;
    }
    .actionsButton span {
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        text-align: right;
        text-transform: uppercase;
        color: #F9F9F9;
    }
}