.container {
    margin: 52px 0 120px 0;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.titleBack {
    background: #f9f9f9;
    z-index: 2;
    padding: 0 72px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    cursor: pointer;
}

.titleBack span {
    height: 20px;
}

.titleText {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    padding: 0 72px 0 82px;
    text-transform: uppercase;
    color: #414141;
    background: #f9f9f9;
    position: relative;
    z-index: 2;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 50%;
    z-index: 1;
}

.inner {
    margin-top: 52px;
    padding: 0 72px;
}

.source {
    /*margin-top: 56px;*/
    /*padding: 0 72px;*/
}

.articleText {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.properties {
    display: flex;
    gap: 24px;
}

.createdAt, .timeToRead {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A4A6A6;
}

.article {
    display: flex;
    gap: 32px;
    align-items: center;
}


.categories {
    display: flex;
    gap: 8px;
}

.category {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 8px;
    border: 1px solid #A4A6A6;
    color: #414141;
}

.text {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #414141;
}

.image {
    height: 100%;
    width: 100%;
}

.image img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.articleText {
    max-width: 50%;
}


.card {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.cardImage {
    height: 273px;
    width: 100%;
}

.cardImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.cardTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #414141;
}

.cardBody {
    padding: 32px 42px 48px 42px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.cardPreview {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    margin-top: 52px;
}

.recommendations {
    padding: 0 72px;
}

.recommendationTitle {
    border-top: 1px solid #bebebe;
    margin-top: 72px;
    padding-top: 72px;

    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    color: #414141;
}

@media (max-width: 768px) {
    .titleBack {
        display: none;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .titleText {
        padding: 0 32px;
        margin: 0 32px;
        background: #f9f9f9;
    }

    .title:after {
        position: absolute;
        content: '';
        background: #A4A6A6;
        width: 100%;
        top: 50%;
        bottom: initial;
        height: 0.7px;
        right: 0;
    }

    .grid {
        display: flex;
        flex-direction: column;
    }

    .article {
        flex-direction: column;
    }

    .articleText {
        max-width: 100%;
    }

    .inner {
        padding: 0 32px;
    }

    .recommendations {
        padding: 0 32px;
    }

    .category {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 133%;
        color: #6A6B6B;
    }


    .text, .source {
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #414141;
    }

    .cardTitle {
        margin-top: 20px;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #414141;
    }

    .cardPreview {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #414141;
    }
}