.react-calendar {
    width: 250px;
    max-width: 100%;
    background: #F9F9F9;
    font-family: 'TT Norms Pro', sans-serif;
    line-height: 1.125em;
}
.react-calendar-table {
    width: inherit !important;
}

.react-calendar-table .react-calendar__tile {
    padding: 2px 10px;
    background-clip: content-box !important;
    margin: 7px 0;
}

.react-calendar--doubleView {
    width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
    background: #F9F9F9;
}

.react-calendar button:enabled:hover {
    cursor: pointer;
}

.react-calendar__navigation {
    margin-bottom: 12px;
    position: relative;
}

.react-calendar__navigation__prev-button {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 0;
}

.react-calendar__navigation__next-button {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
}

.react-calendar__navigation button {
    background: none;
}


.react-calendar__navigation__label {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0;
    margin-bottom: 12px;
}

.react-calendar__navigation button:disabled {
    background-color: #F9F9F9;

    font-family: 'TT Norms Pro';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height */
    text-transform: uppercase;

    /* grey */
    color: #414141;
}

.react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.2;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #F9F9F9;
}

.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
    display: none !important;
}

.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}

.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}

.react-calendar__month-view__days__day--weekend {
    color: #C2272F;
}

.react-calendar__month-view__days__day:not(.react-calendar__month-view__days__day--weekend):not(.react-calendar__tile--active) {
    color: #414141;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}

.react-calendar__tile {
    width: 32px;
    height: 32px;
    font-family: 'TT Norms Pro';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    text-transform: uppercase;
}

.react-calendar__tile:disabled {
    opacity: 0.35;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #F9F9F9;
}

.react-calendar__tile--now {
    background: #F9F9F9;
}


.react-calendar__tile--hasActive {
    background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: #a9d4ff;
}

.react-calendar__tile--active {
    background: #414141 !important;
    color: #F9F9F9;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #414141;
}

.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #F9F9F9;
}