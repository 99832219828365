.modal {
    width: 580px;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    align-self: center;
    z-index: 11;
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;

    transition: all .4s ease-in-out;

    opacity: 0;
}

@-webkit-keyframes trans-in {
    0% {transform: translateY(100px);}
    100% {transform: translateY(0px);}
}
@keyframes trans-in {
    0% {transform: translateY(100px);}
    100% {transform: translateY(0px);}
}

.modalOpen {
    opacity: 1;
    animation: trans-in .4s forwards;
}

.cross {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
}

.inner {
    padding: 48px 75px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
}

.input {
    border: none;
    background: rgba(164, 166, 166, 0.1);
    padding: 14px 24px;
    box-sizing: border-box;
    width: 100%;
    font-family: 'TT Norms Pro', serif;

    font-weight: 500;
    font-size: 16px;
    line-height: 133%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #414141;

}

.input::placeholder {
    font-family: 'TT Norms Pro', serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A4A6A6;
}

.input:focus {
    outline: none;
}

.title {
    display: flex;
    gap: 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.titleText {
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-align: right;
    letter-spacing: 0.085em;
    text-transform: uppercase;
    color: #414141;
}

.titleSub span + span {
    margin-left: 24px;
}

.titleSub span:nth-child(1) {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    letter-spacing: 0.14em;
    text-transform: uppercase;
    color: #414141;
}

.titleSub span:nth-child(2) {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    color: #C2272F;
}

.body {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.bodyText {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;

    text-align: center;
}
.bodyError {
    display: flex;
    align-items: center;
    gap: 12px;
    justify-content: center;
}
.bodyError img {
    width: 18px;
    height: 18px;
}
.bodyError span {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #C2272F;
}

.bodyInput {
    position: relative;
}

.bodyButton {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    gap: 16px;
    height: 33px;
    background: #C2272F;

    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-transform: uppercase;
    color: #FFFFFF;
    cursor: pointer;

    right: 0;
    top: 0;
    position: absolute;
}

.agreement, .agreement a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #A4A6A6;
}

.titlePhone {
    display: flex;
    gap: 24px;
    align-items: flex-end;
}

.titlePhone span:nth-child(1) {
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: right;
    text-transform: uppercase;
    color: #414141;
}

.titlePhone span:nth-child(2) {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 133%;
    text-align: right;
    text-decoration-line: underline;
    color: #A4A6A6;
    cursor: pointer;
}

.countdown {
    display: flex;
    justify-content: center;
}

.countdownBlock {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 133%;
    text-align: center;
    text-decoration-line: underline;
    color: #A4A6A6;
}

.countdownAction {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 133%;
    text-align: center;
    text-decoration-line: underline;
    color: #414141;
    cursor: pointer;
}

@media (max-width: 768px) {
    .modal {
        top: 100px;
        width: calc(100% - 40px);
        box-sizing: border-box;
        position: relative;
    }

    .inner {
        padding: 48px 30px;
        gap: 24px;
    }

    .titleSub span:nth-child(1), .titleSub span:nth-child(2)  {
        font-size: 18px;
        line-height: 133%;
        font-weight: 500;
    }

    .bodyText {
        font-weight: 500;
        font-size: 17px;
        line-height: 133%;
    }

    .bodyInput input {
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
    }

    .agreement {
        font-weight: 400;
        font-size: 15px;
        line-height: 20px;
    }
}