.modal {
    width: 580px;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
    margin: 0 auto;
    align-self: center;
    z-index: 11;
    position: absolute;
    top: 150px;
    left: 0;
    right: 0;

    transition: all .4s ease-in-out;

    opacity: 0;
}

@-webkit-keyframes trans-in {
    0% {transform: translateY(100px);}
    100% {transform: translateY(0px);}
}
@keyframes trans-in {
    0% {transform: translateY(100px);}
    100% {transform: translateY(0px);}
}

.modalOpen {
    opacity: 1;
    animation: trans-in .4s forwards;
}

.cross {
    cursor: pointer;
    position: absolute;
    right: 25px;
    top: 25px;
}

.inner {
    padding: 48px 75px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    width: 100%;
    align-items: center;
}

.title {
    display: flex;
    gap: 16px;
    align-items: center;
}

.titleIcon {
    width: 10px;
    height: 10px;
    background: #C2272F;
    border-radius: 50px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 38px;
    text-align: right;
    letter-spacing: 0.085em;
    text-transform: uppercase;
    color: #414141;
}

.operating {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    text-transform: uppercase;
    color: #6A6B6B;
    max-width: 400px;
}
.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.disclamer {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    color: #414141;
}

.thank {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #414141;
}


@media (max-width: 768px) {
    .modal {
        top: 100px;
        width: calc(100% - 40px);
        box-sizing: border-box;
        position: relative;
    }

    .inner {
        padding: 48px 30px;
        gap: 24px;
    }

    .titleText {
        font-style: normal;
        font-weight: 500;
        font-size: 32px;
        line-height: 38px;
        text-align: right;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
    }

    .operating {
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        text-transform: uppercase;
        color: #6A6B6B;
    }

    .disclamer {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #414141;
    }

    .thank {
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: #414141;
    }

}