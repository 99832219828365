.container {
    margin: 52px 0 120px 0;
}

.title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.titleBack {
    background: #f9f9f9;
    z-index: 2;
    padding: 0 72px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #6A6B6B;
    cursor: pointer;
}

.titleBack span {
    height: 20px;
}

.titleText {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 36px;
    letter-spacing: 0.15em;
    padding: 0 72px 0 82px;
    text-transform: uppercase;
    color: #414141;
    background: #f9f9f9;
    position: relative;
    z-index: 2;
}

.title:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.7px;
    background: #A4A6A6;
    top: 50%;
    z-index: 1;
}

.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    background: #FFFFFF;
    box-shadow: 0px 4px 14px 5px rgba(0, 0, 0, 0.2);
}

.image {
    height: 273px;
    width: 100%;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.grid {
    padding: 0 72px;
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 24px
}

.grid .card:nth-child(1) {
    grid-column: 6 span !important;
}


.grid .card:nth-child(5n-3),
.grid .card:nth-child(5n-2),
.grid .card:nth-child(5n-1) {
    grid-column: 2 span;
}

.grid .card:nth-child(5n),
.grid .card:nth-child(5n+1) {
    grid-column: 3 span;
}

.articleTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #414141;
}

.body {
    padding: 32px 42px 48px 42px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}


.grid .card:nth-child(1)  {
    display: flex;
    flex-direction: row-reverse;
}

.grid .card:nth-child(1) .body  {
    padding: 95px 100px;
}

.grid .card:nth-child(1) .image  {
    height: 100%;
}

.grid .card:nth-child(1) > div {
    width: 100%;
}

.cardTags {
    display: flex;
    gap: 8px;
}

.cardTag {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 8px 8px;
    border: 1px solid #A4A6A6;
    color: #414141;
}

.preview {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #414141;
}

.tags {
    display: flex;
    gap: 18px;
    padding: 32px 120px 72px;
    flex-wrap: wrap;
}

.tag {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 4px;
    background: #F9F9F9;
    border: 1px solid #A4A6A6;

    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #6A6B6B;
}

.tag.active {
    color: #F9F9F9;
    border: 1px solid #414141;
    background: #414141;
}


@media (max-width: 768px) {
    .titleBack {
        display: none;
    }

    .title {
        font-style: normal;
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.085em;
        text-transform: uppercase;
        color: #414141;
        justify-content: center;

    }

    .titleText {
        margin: 0 32px;
        padding: 0 32px;
    }

    .title:after {
        position: absolute;
        content: '';
        background: #A4A6A6;
        width: 100%;
        top: 50%;
        bottom: initial;
        height: 0.7px;
        right: 0;
    }

    .grid {
        padding: 0 32px;
        display: flex;
        flex-direction: column;
    }

    .image {
        height: 250px;
    }

    .articleTitle {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
        text-transform: uppercase;
        color: #414141;
    }

    .preview {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #414141;
    }

    .grid .card:nth-child(1)  {
        display: flex;
        flex-direction: column;
    }

    .grid .card:nth-child(1) .body  {
        padding: 32px 42px 48px 42px;
    }

    .grid .card:nth-child(1) .image  {
        height: 250px;
        width: 100%;
    }

    .grid .card:nth-child(1) > div {
        width: inherit;

    }

    .tags {
        padding: 32px;
    }

    .tag {
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #6A6B6B;
    }

    .cardTag {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 133%;
        color: #414141;
    }

    .articleTitle {
        margin-top: 20px;
    }


}