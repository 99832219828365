.card {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #F9F9F9;
    box-shadow: 0 4px 14px 5px rgba(0, 0, 0, 0.2);
    position: relative;
}

.image {
    width: 100%;
    height: 199px;
    cursor: pointer;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.inner {
    padding: 24px 16px 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    width: 100%;
    box-sizing: border-box;
}

.discountIcon {
    position: absolute;
    width: 36px;
    right: -10px;
    top: -15px;
}

.header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 10px;
    height: 44px;
    width: 100%;
}

.title {
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    text-transform: uppercase;
    color: #414141;
    cursor: pointer;
}

.tags {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    padding: 0px;
}

.body {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    height: 35px;
    overflow: hidden;
    color: #6A6B6B;
    position: relative;
}

.footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-top: 15px;
}

.weight {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    opacity: 0.8;
}

.additionalItem {
    width: 100%;
    padding: 0 12px;
    box-sizing: border-box;
}

.additionalItemImage {
    height: 252px;
}

.additionalItemImage img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.additionalItemFooter {
    margin-top: 16px;

}

.additionalItemTitle {
    display: grid;
    grid-template-columns: auto auto;
}

.additionalItemText {
    width: 100%;
}

.additionalItemTitleText {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-transform: uppercase;
    color: #414141;
}

.additionalItemButton {
    display: flex;
    justify-content: flex-end;
}
.additionalItemWeight{}
.additionalItemButton > div {
    padding-left: 10px;
    padding-right: 10px;
    width: 110px;
}

@media (max-width: 768px) {
    .card {
        width: initial;
    }

    .inner {
        flex-direction: column-reverse;
        padding: 32px 14px 24px;
        gap: 8px;
    }

    .footer {
        margin-top: initial;
    }

    .header {
        height: initial;
    }

    .title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 133.3%;
        text-transform: uppercase;
        color: #414141;
    }

    .body {
        display: none;
    }

    .weight {
        display: none;
    }

    .additionalItemTitle {
        display: flex;
        justify-content: space-between;
    }

    .additionalItemTitleText {
        font-size: 22px;
    }

    .additionalItemWeight {
        font-size: 18px;
    }

    .additionalItemButton {
        display: block;
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .additionalItemButton > div {
        padding: 10px 0;
        width: 70%;
        margin-left: 0;
        max-width: 100%;

    }

    .additionalItemButton > div > div {
        font-size: 24px;
    }

    .tags img {
        width: 24px;
    }
}